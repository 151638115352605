import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ProductBrandType } from '../types';
import { QUERY_KEY_PRODUCT_BRANDS } from '../../features/constants';
import { getProductBrands } from '../products';

export default function useProductBrands(
  queryOption = {},
): UseQueryResult<ProductBrandType[], unknown> {
  return useQuery({
    queryKey: [QUERY_KEY_PRODUCT_BRANDS],
    queryFn: getProductBrands,
    ...queryOption,
  });
}
