import useBonusesInfo from '../../api/hooks/useBonusesInfo';
import { EMPTY_ARRAY } from '../../features/constants';

export default function BonusPage() {
  const { data: bonusesInfo } = useBonusesInfo();
  const bonuses = bonusesInfo?.bonuses || 0;
  const history = bonusesInfo?.bonus_actions || EMPTY_ARRAY;
  return (
    <div className="space-y-5 px-5 pt-5">
      {/* Баллов */}
      <div
          // href="/select-and-order"
        className="border h-150 relative flex rounded-18 overflow-hidden"
      >
        <div className="w-1/2 flex pt-8 pl-7">
          <div className="space-y-0">
            <h2 className=" text-3xl font-semibold leading-none">{bonuses}</h2>
            <div className="space-y-5 font-light leading-none">
              <p>Баллов</p>
              <p>Баллов 1 балл = 1 ₽</p>
            </div>
          </div>
        </div>
        <div className="w-1/2">
          <img src="/assets/images/bag.png" className="w-full h-full" alt="Bonus" />
        </div>
      </div>
      <div>
        <h3 className="mb-1">История</h3>
        {history.length === 0
          ? (<p>Пока что у тебя нет истории начислений баллов.</p>)
          : history.map(({
            order, amount, action_type, math_symbol,
          }) => {
            const negative = math_symbol === '-';
            return (
              <div className="flex justify-between mb-0.5">
                <p key={order}>
                  {negative ? 'Списание' : 'Начисление'}
                </p>
                <p key={order} className="text-black">
                  {math_symbol}
                  {' '}
                  {String(Number(amount).toFixed(0))}
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
}
