import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getFilters } from '../products';
import { ProductFiltersType } from '../types';

type OptionsType = {
  onSuccess?: (data: ProductFiltersType) => void,
  [key: string]: any;
}

export default function useProductsFilter(options: OptionsType = {}) : UseQueryResult<ProductFiltersType> {
  return useQuery({
    queryKey: ['d'],
    queryFn: () => getFilters(options?.onSuccess),
    ...options,
  });
}
