import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ID } from '../../app/types';
import { ProductModification } from '../types';
import { getProductModification } from '../products';
import { QUERY_KEY_PRODUCTS_MODIFICATIONS } from '../../features/constants';

export const PRODUCTS__MODIFICATIONS_QUERY = 'products_modifications';

export default function useProductModification({
  id,
  options = {},
}: {id: ID, options?}): UseQueryResult<ProductModification[], unknown> {
  const queryKey = [QUERY_KEY_PRODUCTS_MODIFICATIONS, id];
  return useQuery({
    queryKey,
    queryFn: () => getProductModification({ id, ...(options?.onSuccess && { onSuccess: options?.onSuccess }) }),
    ...options,
  });
}
