import {
  DefaultError, InfiniteData, useInfiniteQuery, UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { getProducts } from '../products';
import { PaginationRequestType, Product } from '../types';
import { ProductsFilter } from '../../app/types';
import { QUERY_KEY_PRODUCTS_INFINITY } from '../../features/constants';

type TYPES = {
  filter?: ProductsFilter & {limit?: number | null},
  options?: {
    [key: string]: any,
  }
}

export default function useProductsInfinity({
  filter = {
    search: null,
    brand: null,
    size: null,
    sex: null,
    minCost: null,
    maxCost: null,
    sort: null,
    isPromotion: false,
    limit: 10,
  },
  options = {},
}: TYPES = {}): UseInfiniteQueryResult<InfiniteData<PaginationRequestType<Product>>, DefaultError> {
  console.log('JSON.stringify(filter)', JSON.stringify(filter));
  const queryKey = [QUERY_KEY_PRODUCTS_INFINITY, JSON.stringify(filter)];
  return useInfiniteQuery({
    initialPageParam: {
      start: 0,
      limit: filter?.limit || null,
    },
    queryKey,
    getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) => {
      // @ts-ignore
      if (!lastPage?.count || lastPageParam.start + filter.limit >= lastPage.count || !filter?.limit) return null;
      return {
        start: lastPageParam.start + filter.limit,
        limit: filter?.limit,
      };
    },
    getPreviousPageParam: (lastPage, allPages, lastPageParam, allPageParams) => {
      if (lastPageParam.start === 0 || !filter?.limit) return null;
      return {
        start: lastPageParam.start - filter.limit,
        limit: filter?.limit,
      };
    },
    queryFn: ({ pageParam }) => getProducts({ ...filter, ...pageParam }),
    ...options,
  });
}
