import * as yup from 'yup';
import { clearPhoneNumber } from '../helpers';

const requiredMessage = 'Это поле обязательно к заполнению';
export const linkPhoneValidation = yup.object().shape({
  phone: yup.string().required(requiredMessage).test({
    name: 'phone',
    message: 'Номер введен не полностью',
    test: (val) => {
      if (clearPhoneNumber(val).length === 12) return true;
      return false;
    },
  }),
  step: yup.number(),
  confirmation_phone: yup.string(),
});
