import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { filtersSlice, FiltersSliceTypes } from './filtersSlice';
import { STORAGE_KEY } from '../constants';

export type GlobalStoreInterface = {
  filters: FiltersSliceTypes
}

const store = (set) => ({
  filters: filtersSlice(set),
});

export const useGlobalStore = create<GlobalStoreInterface>()(devtools(store, {
  name: STORAGE_KEY,
  // storage: createJSONStorage(() => localStorage),
  // merge: (persistedState, currentState) => mergeDeepLeft(persistedState, currentState),
}));

// main

export const globalStoreSelectorFilters = (state: GlobalStoreInterface) => ({
  filters: state.filters,
  setFilters: state.filters.setFilters,
  setFiltersInitialState: state.filters.setFiltersInitialState,
});

