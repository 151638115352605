import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ReferalInfoRes } from '../types';
import { getClientReferalInfo } from '../client';
import { QUERY_KEY_REFERRAL_INFO } from '../../features/constants';


export default function useReferalInfo(
  queryOption = {},
): UseQueryResult<ReferalInfoRes, unknown> {
  const queryKey = [QUERY_KEY_REFERRAL_INFO];
  return useQuery({
    queryKey,
    queryFn: getClientReferalInfo,
    ...queryOption,
  });
}
