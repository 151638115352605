import { useField } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useRef } from 'react';
import MaskedInput from 'react-text-mask';
import { cx } from '../../../../features/helpers';

type Types = {
  name: string;
  placeholder?: string;
  mask?: (RegExp | string | number)[];
  containerClassName?: string;
};

export default function FormikInput({
  name,
  placeholder = '',
  mask,
  containerClassName = '',
}: Types) {
  const fieldRef = useRef<HTMLInputElement>(null);
  const [field, { touched, error, value }, { setValue, setTouched }] = useField(name);
  const isError = error && touched;
  return (
    <div className="relative pb-6">
      <div
        className={cx(
          'h-11 flex items-center gap-3 rounded-[10px] search-input-shadow pl-5 pr-[18px]',
          containerClassName,
        )}
      >
        {mask ? (
          <MaskedInput
            mask={mask}
            guide={false}
            {...field}
            render={(textMaskRef, props) => (
              <input
                placeholder={placeholder}
                className="w-full text-xs placeholder:text-brand-gray-200 placeholder:font-normal font-medium outline-none leading-normal"
                ref={(node) => {
                  textMaskRef(node);
                  // @ts-ignore
                  fieldRef.current = node;
                }}
                {...props}
                onBlurCapture={() => window.scrollTo(0, 300)}
              />
            )}
          />
        ) : (
          <input
            type="text"
            placeholder={placeholder}
            className="w-full text-xs placeholder:text-brand-gray-200 placeholder:font-normal font-medium outline-none leading-normal"
            {...field}
          />
        )}
      </div>
      <AnimatePresence>
        {isError && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="text-red-500 absolute right-0 bottom-0"
          >
            {error}
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  );
}
