import { RefObject, useEffect, useState } from 'react';
import { FUNCTION_RETURN_NULL } from '../constants';

type Types = {
  ref: RefObject<HTMLDivElement | HTMLParagraphElement | HTMLSpanElement> | RefObject<null>,
  callback: () => void,
  disabled: boolean,
}

export default function useOnScreen({ ref, callback = FUNCTION_RETURN_NULL, disabled = false } : Types) {
  const [isIntersecting, setIntersecting] = useState(false);
  const observer = new IntersectionObserver(
    ([entry]) => {
      setIntersecting(entry.isIntersecting);
      console.log('disable', entry.isIntersecting);
      if (entry.isIntersecting) callback();
    },
  );

  useEffect(() => {
    if (!ref.current) return;
    if (!disabled) {
      observer?.observe(ref.current);
    } else if (observer) {
      // eslint-disable-next-line consistent-return
      return observer.disconnect();
    }
    // eslint-disable-next-line consistent-return
    return () => { observer.disconnect(); };
  }, [disabled, callback]);

  return disabled ? null : isIntersecting;
}
