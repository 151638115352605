import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // withCredentials: true,
});

let telegramInitData = 'query_id=AAHuX8IvAAAAAO5fwi89obmy&user=%7B%22id%22%3A801267694%2C%22first_name%22%3A%22%D0%9A%D0%B8%D1%80%D0%B8%D0%BB%D0%BB%22%2C%22last_name%22%3A%22%F0%9F%94%92%22%2C%22username%22%3A%22kirill_5357%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1711542981&hash=edb7cc590f8d0f734ce1652ccc67813b7953959a80abfd1d13baa0518dde33a5';
if (process.env.NODE_ENV === 'production') {
  telegramInitData = window.Telegram.WebApp.initData;
}
console.log('telegramInitData', telegramInitData);
// const telegramInitData = 'wd';

const mockData = 'query_id=AAHuX8IvAAAAAO5fwi89obmy&user=%7B%22id%22%3A801267694%2C%22first_name%22%3A%22%D0%9A%D0%B8%D1%80%D0%B8%D0%BB%D0%BB%22%2C%22last_name%22%3A%22%F0%9F%94%92%22%2C%22username%22%3A%22kirill_5357%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1711542981&hash=edb7cc590f8d0f734ce1652ccc67813b7953959a80abfd1d13baa0518dde33a5';

if (telegramInitData) {
  axiosInstance.defaults.headers.common.Authorization = `tma ${telegramInitData}`;
}
console.log('window.Telegram 1', window.Telegram.WebApp);
export default axiosInstance;
