import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { replaceDotsForSpace } from '../../../../features/helpers';

type Types = {
  value: any,
  priceRange: number[],
  handleMinCostChange: (data: number) => void,
  handleMaxCostChange: (data: number) => void,
  isFiltersOpen: boolean,
}

const initialState = {
  min: '',
  max: '',
};

export default function SortByCost({
  value,
  priceRange,
  handleMinCostChange,
  handleMaxCostChange,
  isFiltersOpen = false,
} : Types) {
  const [state, setState] = useState(initialState);
  const { min, max } = state;
  // @ts-ignore
  const debouncedSetFilters = useCallback(debounce((min, max) => {
    handleMinCostChange(min);
    handleMaxCostChange(max);
  }, 500), []);

  useEffect(() => {
    if (isFiltersOpen) {
      debouncedSetFilters(state.min, state.max);
    }
  }, [state]);

  useEffect(() => {
    if (isFiltersOpen && (value[0] && value[0] !== state.min) && (value[1] && value[1] !== state.max)) {
      setState({
        min: value[0] ? String(value[0]) : '',
        max: value[1] ? String(value[1]) : '',
      });
    }
  }, [isFiltersOpen]);
  return (
    <div className="p-5 space-y-4">
      <h4 className="text-base font-bold ml-5">Цена от и до</h4>
      <Slider
        className="ml-5 mt-5 w-auto range-slider"
        pushable
          // @ts-ignore
        dotStyle={(dotStyle) => dotStyle}
        handleStyle={[
          {
            borderColor: 'red',
            height: '24px',
            width: '24px',
            marginLeft: '-12px', // Центрирование точки относительно ползунка
            marginTop: '-9px', // Небольшое смещение вверх, если требуется
            backgroundColor: 'red',
          },
          {
            borderColor: 'red',
            height: '24px',
            width: '24px',
            marginLeft: '-12px',
            marginTop: '-9px',
            backgroundColor: 'red',
          },
        ]}
        trackStyle={[{ backgroundColor: 'red' }, { backgroundColor: 'red' }]}
        range
        min={priceRange?.[0] || 0}
        max={priceRange?.[1] || 150000}
        value={[(Number(min) || 0), (Number(max) || 150000)]}
        onChange={(value) => {
          setState({
            min: value[0],
            max: value[1],
          });
        }}
        step={500}
      />
      <div className="grid grid-cols-2 auto gap-5 px-5">
        <input
          type="number"
          className="h-11 rounded-[10px] font-medium bg-brand-gray-100 border border-brand-gray-100 focus:bg-white focus:border-brand-gray-200 outline-none px-5"
          placeholder={`от ${replaceDotsForSpace(priceRange?.[0] || 0)} ₽`}
          value={min}
          onChange={(e) => setState((prevState) => ({ ...prevState, min: e.target.value }))}
        />
        <input
          type="number"
          className="h-11 rounded-[10px] font-medium bg-brand-gray-100 border border-brand-gray-100 focus:bg-white focus:border-brand-gray-200 outline-none px-5"
          placeholder={`до ${replaceDotsForSpace(priceRange?.[1] || 150000)} ₽`}
          value={max}
          onChange={(e) => setState((prevState) => ({ ...prevState, max: e.target.value }))}
        />
      </div>
    </div>
  );
}
