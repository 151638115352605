import { useMemo } from 'react';
import useOrders from '../../../api/hooks/useOrders';
import { EMPTY_ARRAY } from '../../../features/constants';
import { OrdersResponseType } from '../../../api/orders/types';
import OrdersList from './OrdersList';
import SkeletonList from './Skeleton';

export default function Orders() {
  const { data: orders = [], isLoading } = useOrders();
  const [waitForPay, paid, canceled] = useMemo(() => {
    if (orders.length === 0) return [EMPTY_ARRAY, EMPTY_ARRAY, EMPTY_ARRAY];

    const waitForPay: OrdersResponseType[] = [];
    const paid: OrdersResponseType[] = [];
    const canceled: OrdersResponseType[] = [];

    orders.forEach((order) => {
      if (order.status === 'Ожидает оплаты') waitForPay.push(order);
      if (order.status !== 'Ожидает оплаты' && order.status !== 'Отменен' && order.status !== 'Возвраты') paid.push(order);
      if (order.status === 'Отменен' || order.status === 'Возвраты') canceled.push(order);
    });
    return [waitForPay, paid, canceled];
  }, [orders]);

  return (
    <div>
      {isLoading && <SkeletonList />}
      {(orders.length === 0 && !isLoading) && <p className="text-center my-4">У вас нет заказов</p>}
      {!isLoading && (
        <>
          <OrdersList title="Ожидает оплаты" cards={waitForPay} />
          <OrdersList title="В процессе доставки" cards={paid} className="mt-4" />
          <OrdersList title="Возвраты и отмены" cards={canceled} className="mt-4" />
        </>
      )}
    </div>
  );
}
