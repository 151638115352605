import { Fragment, useMemo, useState } from 'react';
import Button from '../../components/common/Button';
import useTelegramApp from '../../features/hooks/useTelegramApp';
import useClientInfo from '../../api/hooks/useClientInfo';
import useBonusesInfo from '../../api/hooks/useBonusesInfo';
import LinkPhoneModal from '../../components/common/Modals/LinkPhoneModal';
import { formatPhoneNumber } from '../../features/helpers';
import EditDeliveryInfoModal from '../../components/common/Modals/EditDeliveryInfoModal';
import useBonuses from '../../api/hooks/useBonuses';
import { EMPTY_ARRAY } from '../../features/constants';

const statuses = ['Бронза', 'Серебро', 'Золото'];

export default function UserPage() {
  const { data: client } = useClientInfo();
  const { data: bonuses } = useBonusesInfo();
  const { data: bonusesTable = EMPTY_ARRAY } = useBonuses();
  const [isOpenLinkPhoneModal, setIsOpenLinkPhoneModal] = useState(false);
  const [isOpenDeliveryInfoModal, setIsOpenDeliveryInfoModal] = useState(false);
  const bonusesToNextStatus = bonuses?.count_orders_to_next_bonus_level || 0;
  const completedOrders = client?.orders_info?.completed_orders_count || 0;
  const status = client?.bonus_level?.title || '';
  const cashback = client?.bonus_level?.cahsback_percent || '';
  const statusIndex = useMemo(() => statuses.indexOf(status), [status]);
  const lastStatus = statusIndex === statuses.length - 1;
  const telegramApp = useTelegramApp();
  const firstName = telegramApp.initDataUnsafe.user?.first_name ?? 'Test';
  const lastName = telegramApp.initDataUnsafe.user?.last_name ?? 'user';
  console.log('client', client);
  const firstname = client?.delivery_info?.first_name || '';
  const secondname = client?.delivery_info?.second_name || '';
  const lastname = client?.delivery_info?.last_name || '';
  const isNameExist = !!firstname || !!secondname || !!lastname;
  const name = `${firstname} ${secondname} ${lastname}`;
  return (
    <div>
      <div className="h-72 xs:h-80 grid place-content-center bg-brand-gray-100 pb-8 p-5">
        <div className="space-y-2">
          <div className="w-[150px] h-150 bg-[#C184D0] rounded-full overflow-hidden">
            <img
              src="/assets/images/avatar.png"
              alt="User logo"
              className="w-full h-full"
            />
          </div>
          <h3 className="text-center">
            {firstName}
            {' '}
            {lastName}
          </h3>
        </div>
      </div>
      <div className="relative divide-y divide-brand-gray-100 z-10 bg-white rounded-t-18 pt-6 -mt-5 px-5">
        <div className="space-y-3 pb-4">
          <h3>
            Ваш статус:
            {' '}
            <span className="text-brand-red ml-2">{status}</span>
          </h3>
          <p>
            Вы получаете
            {' '}
            <span className="text-brand-red">
              {cashback}
              %
            </span>
            {' '}
            c
            каждого заказа
          </p>
        </div>
        <div className="py-4">
          {completedOrders === 0
            ? (
              <>
                У вас нет завершенных заказов. Закажи еще
                {' '}
                {bonusesToNextStatus}
                {' '}
                товар(а), чтобы
                получить статус «
                {statuses[statusIndex + 1]}
                ».
              </>
            )
            : (
              lastStatus
                ? (
                  <p>
                    У вас
                    {' '}
                    {completedOrders}
                    {' '}
                    заказ(ов).
                    {' '}
                  </p>
                )
                : (
                  <p>
                    У вас
                    {' '}
                    {completedOrders}
                    {' '}
                    завершенных заказ(ов).
                    Заверши еще
                    {' '}
                    {bonusesToNextStatus}
                    {' '}
                    заказ(ов), чтобы
                    получить статус «
                    {statuses[statusIndex + 1]}
                    ».
                  </p>
                )
            )}
        </div>
        {bonusesTable.length !== 0 && (
        <>
          <div className="grid grid-cols-3 text-base py-2 font-bold">
            <div>Статус</div>
            <div className="justify-self-center">Кешбек, %</div>
            <div className="justify-self-end">Заказов</div>
          </div>
          <div className="grid grid-cols-3 text-[0.875rem] font-semibold py-4 gap-2.5">
            {bonusesTable.map(({ title, cahsback_percent, orders_limit }) => (
              <Fragment key={title}>
                <div>{title}</div>
                <div className="justify-self-center">{cahsback_percent}</div>
                <div className="justify-self-end">{orders_limit}</div>
              </Fragment>
            ))}
          </div>
        </>
        )}
        <div className="space-y-5 py-4">
          <div>
            <h5 className="text-base font-bold">Телефон</h5>
            {client?.phone && <p>{formatPhoneNumber(client?.phone)}</p>}
            {client?.phone === null && (
            <p>
              Если привяжешь свой телефон, мы сможем перенести твои старые
              заказы в приложение
            </p>
            )}
          </div>
          {client?.phone === null && (
          <Button
            className="h-12 flex items-center gap-2.5  bg-black text-white text-base px-5 py-4"
            onClick={() => setIsOpenLinkPhoneModal(true)}
          >
            Привязать телефон
          </Button>
          )}
        </div>
        <div className="space-y-5 py-4">
          <div>
            <h5 className="text-base font-bold">Данные доставки</h5>
            <p>
              Заполните данные о доставке перед тем как совершить заказ
            </p>
          </div>
          <div className="space-y-3.5">
            <div className="flex items-center justify-between text-sm font-semibold">
              <div>ФИО получателя</div>
              {isNameExist ? (<div>{name}</div>) : (<div>Не указан</div>)}
            </div>
            <div className="flex items-center justify-between text-sm font-semibold">
              <div>Телефон</div>
              {client?.delivery_info?.phone ? (formatPhoneNumber(client?.delivery_info?.phone)) : (<div>Не указан</div>)}
            </div>
          </div>
          <Button className="h-12 flex items-center gap-2.5  bg-black text-white text-base px-5 py-4" onClick={() => setIsOpenDeliveryInfoModal(true)}>
            Редактировать
          </Button>
        </div>
        {/* <div className="space-y-4 pt-4 pb-10">
          <div>
            <div className="space-y-2">
              <h3 className="text-brand-red">Ресейл-аккаунт</h3>
              <p>
                Для оптовых покупателей. Оформи 50+ заказов и получи самый
                выгодный тариф.
              </p>
            </div>
          </div>
          <div className="space-y-2">
            <h5 className="text-base font-bold">Условия</h5>
            <div>
              <p>Скидка на комиссию за заказ: 50%</p>
              <p>Нет начислений баллов</p>
              <p>Нет прогресса по статусу</p>
            </div>
          </div>
          <Button className="h-12 flex items-center gap-2.5  bg-black text-white text-base px-5 py-4">
            Подробнее в статье
          </Button>
        </div> */}
      </div>
      <LinkPhoneModal isOpen={isOpenLinkPhoneModal} onClose={() => setIsOpenLinkPhoneModal(false)} />
      <EditDeliveryInfoModal isOpen={isOpenDeliveryInfoModal} onClose={() => setIsOpenDeliveryInfoModal(false)} />
    </div>
  );
}
