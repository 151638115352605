import React from 'react';
import { ToastContainer } from 'react-toastify';
import { AppRouter } from './app/providers/Router';
import AppNav from './components/common/Logo';
import UserInfo from './components/common/UserInfo';
import 'react-toastify/dist/ReactToastify.css';
import useEruda from './features/hooks/useEruda';

function App() {
  useEruda();
  return (
    <div className="App overflow-hidden">
      <AppNav />
      <UserInfo />
      <AppRouter />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggableDirection="y"
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default App;
