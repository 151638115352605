import SVGDiscount from '../../../svg/SVGDiscount';
import { cx } from '../../../../features/helpers';
import useDraggingScroll from '../../../../features/hooks/useDraggingScroll';

export default function SortBySort({
  onChange,
  sorts,
  selected,
}) {
  const {
    onMouseUp, onMouseMove, onMouseDown, isDragging, ignoreNextClick,
  } = useDraggingScroll();
  return (
    <div className="space-y-4">
      <h4 className="text-base font-bold ml-5">Сортировка</h4>
      <div
        className="scroll-none flex flex-nowrap overflow-auto gap-2.5"
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseUp}
        onMouseUp={onMouseUp}
      >
        {sorts.map((sort, index) => {
          const isSelected = selected === sort?.value;
          return (
            <button
              key={sort?.id}
              onClick={() => {
                if (isDragging || ignoreNextClick) return;
                onChange(sort?.value);
              }}
              className={cx(
                'h-7 flex items-center gap-1 text-sm font-semibold text-brand-gray-200 whitespace-nowrap px-3',
                index === 0 && 'ml-5',
                index === sorts.length - 1 && 'mr-5',
                isSelected && 'bg-brand-red rounded-full text-white border-brand-red',
              )}
            >
              {sort?.label}
              {' '}
              {sort?.label === 'Со скидкой' && (
                <span>
                  {' '}
                  <SVGDiscount className={cx(selected?.id === sort?.id ? 'stroke-white fill-brand-red' : 'stroke-brand-red fill-white')} />
                </span>
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
}
