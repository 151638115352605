import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getOrders } from '../orders';
import { QUERY_KEY_ORDERS } from '../../features/constants';
import { OrdersResponseType } from '../orders/types';

export default function useOrders(): UseQueryResult<OrdersResponseType[]> {
  return useQuery({
    queryKey: [QUERY_KEY_ORDERS],
    queryFn: getOrders,
  });
}
