import { getId } from '../features/helpers';

export const sexes = [
  { id: getId(), value: null, label: 'Всех' },
  { id: getId(), value: 'child', label: 'Для детей' }, // исключение
  { id: getId(), value: 'male', label: 'Мужчин' },
  { id: getId(), value: 'female', label: 'Женщин' },
  { id: getId(), value: 'unisex', label: 'Унисекс' },
];
// @ts-ignore
export const sorts = [
  // { id: getId(), label: 'Популярные' },
  { id: getId(), label: 'Со скидкой', value: 'promo' },
  { id: getId(), label: 'Увеличение цены', value: 'price' },
  { id: getId(), label: 'Снижение цены', value: '-price' },
];
