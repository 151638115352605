import { useEffect } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import Modal from '../../Modal';
import FormikInput from '../../Formik/FormikInput';
import useLinkPhone from '../../../../api/hooks/useLinkPhone';
import useVerifyPhone from '../../../../api/hooks/useVerifyPhone';
import { linkPhoneValidation } from '../../../../features/validations/linkPhoneNumberValidation';
import { errorToast, getFormattedPhoneNumber } from '../../../../features/helpers';
import { QUERY_KEY_CLIENT_INFO } from '../../../../features/constants';

export default function LinkPhoneModal({
  isOpen = false,
  onClose,
  info,
  callback,
}: {
  isOpen: boolean;
  onClose: () => void;
  info?: string;
  callback?: (phone: string) => Promise<void>;
}) {
  return (
    <Modal
      isOpen={isOpen}
      className="pb-14"
      heightClassName="h-[300px]"
      title="Привязать мобильный телефон"
      onClose={onClose}
    >
      <MainContent onClose={onClose} info={info} callback={callback} />
    </Modal>
  );
}

let timer: any | null = null;

function MainContent({ onClose, info, callback }) {
  const queryClient = useQueryClient();
  const { mutateAsync: updatePhone, isPending } = useLinkPhone();
  const { refetch: checkVerifiedPhone } = useVerifyPhone();
  const formik = useFormik({
    initialValues: {
      phone: '',
      step: 0,
      confirmation_phone: '',
    },
    validationSchema: linkPhoneValidation,
    onSubmit: async () => {
      try {
        console.log('submit');
      } catch (e) {
        errorToast({ text: e });
        console.log('e', e);
      }
    },
  });
  const { values, setFieldValue, validateForm } = formik;
  const { step, phone, confirmation_phone } = values;

  function setStep(step) {
    setFieldValue('step', step);
  }

  async function next() {
    const errors = await validateForm();

    if (errors?.phone) return;
    try {
      const phoneClean = phone.replace(/[\s()-]/g, '');
      const res = await updatePhone(phoneClean);
      setFieldValue('confirmation_phone', res?.confirmation_phone);
      setStep(1);
    } catch (e) {
      errorToast({ text: e });
      console.log('e', e);
    }
  }

  const checkVerified = async () => {
    try {
      const { data } = await checkVerifiedPhone();
      if (data && data.is_verified) {
        onClose();
        toast.success('Телефон успешно привязан', { autoClose: 15000 });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY_CLIENT_INFO] });
        clearInterval(timer);
        if (callback) {
          await callback(data.phone);
        }
      }
    } catch (e) {
      errorToast({ text: e });
      console.log('e', e);
    }
  };

  useEffect(() => {
    if (confirmation_phone) {
      timer = setInterval(checkVerified, 2000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [confirmation_phone]);

  return (
    <FormikProvider value={formik}>
      {step === 0 && (
        <div>
          {info && <p className="text-center">{info}</p>}
          <FormikInput
            containerClassName="mt-4"
            placeholder="Номер телефона"
            mask={[
              '+',
              /\d/,
              ' ',
              '(',
              /\d/,
              /\d/,
              /\d/,
              ')',
              ' ',
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            name="phone"
          />
          <div className="flex justify-center">
            <button
              onClick={next}
              disabled={isPending}
              className="min-w-[154px] w-1/2 block mt-4 h-12 bg-black rounded-full text-white disabled:opacity-[0.6]"
            >
              Привязать
            </button>
          </div>
        </div>
      )}
      {step === 1 && (
        <div className="py-4">
          <p className="text-center text-black">
            Для подтверждения телефона вам необходимо позвонить на номер телефона
          </p>
          <p
            className="text-center my-5 text-black"
            onClick={() => window.open(`tel:+${confirmation_phone}`, '_blank')}
          >
            <a className="text-2xl font-bold" href={`tel:+${confirmation_phone}`}>
              {getFormattedPhoneNumber(confirmation_phone)}
            </a>
          </p>
          <p className="text-center text-black">
            Звонок бесплатный, необходимо дождаться сброса звонка
          </p>
          <div className="flex justify-center">
            <button
              onClick={() => setStep(0)}
              className="min-w-[171px] w-1/2 h-12 bg-brand-gray-200 rounded-full mt-4 px-5 text-white"
            >
              Назад
            </button>
          </div>
        </div>
      )}
    </FormikProvider>
  );
}
