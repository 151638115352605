import { useEffect } from 'react';

export default function useEruda() {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      const script = document.createElement('script');
      script.src = '//cdn.jsdelivr.net/npm/eruda';
      script.async = true;
      document.body.appendChild(script);
      /* global eruda */
      script.onload = () => {
        // @ts-ignore
        if (typeof eruda !== 'undefined') {
          // @ts-ignore
          eruda.init();
        }
      };
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);
}
