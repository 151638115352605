import BrandSlider from '../BrandSlider';
import ProductCard from './Product';
import SkeletonList from './SkeletonList';
import { globalStoreSelectorFilters, useGlobalStore } from '../../../../features/store';
import useProductsFilter from '../../../../api/hooks/useProductsFilter';
import { Product } from '../../../../api/types';

export default function ProductsList({ products = [], isLoading = false, reachEnd = false }: {products: Product[], isLoading: boolean, reachEnd: boolean}) {
  const { data: { brands = [], sizes_eu: sizes = [], price_range: priceRange } = {}, isSuccess } = useProductsFilter();
  const { filters, setFilters } = useGlobalStore(globalStoreSelectorFilters);
  const { isOpen, ...rest } = filters;
  const { brand, isSearching } = filters;
  return (
    <div>
      <div className="overflow-auto">
        {!isSearching && <BrandSlider brands={brands} selected={brand} onChange={(brand) => setFilters({ brand })} />}
      </div>
      <div className="grid grid-cols-2 gap-x-5 gap-y-2 px-5 mt-7">
        {isLoading && <SkeletonList />}
        {!isLoading
          && products.map((product) => (
            <ProductCard product={product} key={product.id} />
          ))}
      </div>
      {(reachEnd && products.length !== 0) && (<p className="text-center mt-8">Показаны все продукты</p>)}
    </div>
  );
}
