import cxx from 'classnames/bind';
import { toast } from 'react-toastify';
import { cloneElement, createElement, ReactElement } from 'react';
import { ProductModification } from '../../api/types';
import { ColorFront, ProductSizeType } from '../../app/types';
import { COLORS } from '../constants';

export function appendSearchParams(obj: { [key: string]: string | number }): string {
  const searchParams = new URLSearchParams();

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      searchParams.append(key, String(obj[key]));
    }
  }

  return searchParams.toString();
}

export function removeDuplicates<T>(data: T[], key: keyof T): T[] {
  const seen: Set<any> = new Set();
  const result: T[] = [];

  for (const item of data) {
    if (!seen.has(item[key])) {
      seen.add(item[key]);
      result.push(item);
    }
  }
  return result;
}

export const transformSizesFromApi = (
  product: ProductModification,
): ProductSizeType => ({
  id: product.id,
  description: product.description,
  size: String(Math.round(Number(product.size))),
  insole_size: product.insole_size,
  amount: Number(
    product.price_with_promo ? product.price_with_promo : product.price,
  ),
});

export const findColorOnFront = (product: ProductModification): ColorFront => {
  const findColor = COLORS.find(
    (color) => color.color.toLocaleLowerCase() === product.color.toLocaleLowerCase(),
  );
  const isMultiColor = product.color.split(';').length > 1;
  if (findColor) {
    return { ...findColor, id: product.id, isMultiColor };
  }
  return {
    id: '123', hex: '#fff', color: 'Белый', isMultiColor: false,
  };
};

export function cx(...rest) {
  // @ts-ignore
  return cxx(...rest);
}

export const getId = () => Date.now().toString(36) + Math.random().toString(36).substring(2);

export function replaceDotsForSpace(number: number | string) {
  if (typeof number !== 'string' && typeof number !== 'number') return '';
  const parts = number.toString().split('.');
  const integerPart = parseInt(parts[0], 10).toLocaleString().replace(/,/g, ' ');

  if (parts.length === 2) {
    return `${integerPart}.${parts[1]}`;
  }

  return integerPart;
}

export function toReactKeyString(originalString) {
  // Замена специальных символов на дефисы
  let key = originalString.replace(/[^a-zA-Z0-9]/g, '-');
  let hash = 0;
  for (let i = 0; i < key.length; i++) {
    const character = key.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = ((hash << 5) - hash) + character;
    // eslint-disable-next-line no-bitwise
    hash &= hash; // Преобразование к 32bit целому
  }

  // Преобразование числового хеша в строку и добавление префикса для уникальности
  key = `key-${hash.toString()}`;

  return key;
}

export function clearPhoneNumber(phone = '') {
  return phone.replace(/[\s()-]/g, '');
}

export function formatPhoneNumber(phoneNumber) {
  const match = phoneNumber.match(/^(\+\d)(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
  }
  return phoneNumber;
}

function isObject(obj) {
  return obj instanceof Object && obj.constructor === Object;
}

export function createErrorMessage(element) {
  const obj = element?.response?.data || element?.message || element;
  const arr: string[] = [];
  console.log('error element 122', element);
  if (typeof obj === 'string') return obj;
  // eslint-disable-next-line no-restricted-syntax,guard-for-in
  for (const key in obj) {
    const element = obj[key];
    const keyReplace = key === 'detail' ? '' : key;
    if (Array.isArray(element)) {
      const filteredArray = element.filter((message) => message !== 'error');
      if (filteredArray.length !== 0) {
        const string = filteredArray.join('<br/>');
        // const keyString = ignoreKeys ? '' : (keyReplace ? `${capitalCase(noCase(keyReplace))}:` : '');
        const resultString = `${string}`;
        arr.push(resultString);
      }
    }
    if (typeof element === 'string') {
      // const keyString = ignoreKeys ? '' : (keyReplace ? `${capitalCase(noCase(keyReplace))}:` : '');
      const resultString = `${key}: ${element}`;
      arr.push(resultString);
    }
    if (isObject(element)) {
      const res = createErrorMessage(element);
      arr.push(...res);
    }
  }
  return arr.join('<br/>');
}


export function errorToast({ text, element = createElement('div') } : {text: object | string, element?: ReactElement }) {
  const err = createErrorMessage(text);
  toast.error(cloneElement(element, { dangerouslySetInnerHTML: { __html: err } }));
}

export function getFormattedPhoneNumber(phoneNumber: string): string {
  const cleaned = (`${phoneNumber}`).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1,3})(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
  }
  return phoneNumber; // return original number if not in expected format
}
