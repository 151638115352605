import { useMemo } from 'react';
import Button from '../../../common/Button';
import Skeleton from '../../../ui/Skeleton';
import { cx, getId } from '../../../../features/helpers';
import useDraggingScroll from '../../../../features/hooks/useDraggingScroll';
// @ts-nocheck
// import { SwiperOptions } from 'swiper/types';

type Types = {
  onChange: (brand: string | null) => void,
  brands: (string | null)[],
  selected: string | null,
}

const skeletonBrands = [...Array(7)].map((u, i) => getId());
function BrandSlider({
  onChange,
  brands = [],
  selected,
}: Types) {
  const swiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 10,
  };
  const {
    onMouseUp, onMouseMove, onMouseDown, isDragging, ignoreNextClick,
  } = useDraggingScroll();
  const slides = useMemo(() => {
    if (brands.length === 0) {
      return skeletonBrands.map((element, index) => (
        <Skeleton
          key={element}
          className={cx('w-20 h-7 rounded-full', index === 0 && 'ml-5')}
        />
      ));
    }
    return brands.map((brand, index) => {
      const isSelected = brand === selected;
      console.log('brand', brand);
      return (
        <Button
          key={brand}
          onClick={() => {
            if (isDragging || ignoreNextClick) return;
            onChange(brand);
          }}
          className={cx(
            'h-7 border flex items-center border-brand-gray-200 rounded-full text-sm font-semibold text-brand-gray-200 whitespace-nowrap px-3 mr-2',
            index === 0 && 'ml-5',
            index === brands.length - 1 && 'mr-5',
            isSelected && 'bg-brand-red text-white border-brand-red',
          )}
        >
          {/* {brand.brand} */}
          {brand === null ? 'Все' : brand}
        </Button>
      );
    });
  }, [brands, selected, isDragging, ignoreNextClick]);

  return (
    <div
      className="mt-7 w-full flex overflow-auto pb-3 scroll-none"
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseUp}
      onMouseUp={onMouseUp}
    >
      {slides}
    </div>
  );
}

export default BrandSlider;
