import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Autoplay, Pagination } from 'swiper/modules';
import Button from '../../../common/Button';
import usePromotions from '../../../../api/hooks/usePromotions';
import { globalStoreSelectorFilters, useGlobalStore } from '../../../../features/store';

export default function MainProductSlider() {
  const { data: promotions = [] } = usePromotions();
  const { setFilters } = useGlobalStore(globalStoreSelectorFilters);
  console.log('promotions', promotions);

  return (
    <div className="relative mt-10 mb-8">
      <Swiper
        loop={promotions.length > 1}
        pagination
        speed={promotions.length > 1 ? 800 : 0}
        {...(promotions.length > 1 ? { modules: [Pagination, Autoplay] } : { modules: [] })}
        // autoplay={{
        //   delay: 2500,
        //   disableOnInteraction: false,
        // }}
        className="mySwiper main-slider"
      >
        {promotions.map(({ id, title, image }) => (
          <SwiperSlide key={id}>
            <div
              className="border border-black/10 rounded-18 456px:h-[220px] flex flex-col-reverse 456px:flex-row items-center 456px:items-start"
            >
              <div className="self-start 456px:self-auto">
                <div className="space-y-3 p-5">
                  <h3 className="456px:w-32 text-2xl font-semibold leading-none">
                    {title}
                  </h3>
                  <Button
                    onClick={() => setFilters({ isSearching: true, isPromotion: true })}
                    // secondary
                    className="h-8 bg-black whitespace-nowrap text-xs text-white px-5 py-2.5"
                  >
                    Купить сейчас
                  </Button>
                </div>
              </div>
              <div className="relative col-span-3">
                <img
                  src={image}
                  alt={title}
                  className="w-80 456px:w-96 h-auto transition-all duration-1000 opacity-100 mt-[-2rem]"
                //   width={189}
                //   height={250}
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
