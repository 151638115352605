import OrderCard from '../OrderCard';
import { OrdersResponseType } from '../../../../api/orders/types';

type Types = {
    title: string,
    cards: OrdersResponseType[],
    className?: string
}

export default function OrdersList({ title, cards = [], className = '' } : Types) {
  if (cards.length === 0) return null;
  return (
    <div className={className}>
      <p className="text-black font-bold text-2xl ml-3">{title}</p>
      <div className="w-full mx-auto max-w-[320px] 420px:max-w-none grid 420px:grid-cols-2 gap-2.5 mt-5">
        {
            cards.map((order) => (
              <OrderCard order={order} key={order.id} />
            ))
        }
      </div>
    </div>
  );
}
