export default function SkeletonList() {
  const list = [...Array(3)].map((u, i) => i);

  return (
    <>
      <div className="animate-pulse space-y-2.5 mb-6">
        <div className="text-base">
          <div className="font-bold h-8 rounded-18 bg-brand-gray-100" />
        </div>
        <div className="grid grid-cols-2 gap-2">
          {list.map((index) => (
            <div key={index} className="h-40 bg-brand-gray-100 rounded-18" />
          ))}
        </div>
      </div>
      <div className="animate-pulse space-y-2.5">
        <div className="text-base">
          <div className="font-bold h-8 rounded-18 bg-brand-gray-100" />
        </div>
        <div className="grid grid-cols-2 gap-2">
          {list.map((index) => (
            <div key={index} className="h-40 bg-brand-gray-100 rounded-18" />
          ))}
        </div>
      </div>
    </>
  );
}
