function DocumentSvg() {
  return (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 17V19C13 20.1046 12.1046 21 11 21H3C1.89543 21 1 20.1046 1 19V8C1 6.89543 1.89543 6 3 6H5M11.6847 1H7C5.89543 1 5 1.89543 5 3V15C5 16.1046 5.89543 17 7 17H15C16.1046 17 17 16.1046 17 15V6.24162C17 5.7034 16.7831 5.18789 16.3982 4.81161L13.0829 1.56999C12.7092 1.2046 12.2074 1 11.6847 1Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DocumentSvg;
