import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import ArrowSvg from '../../svg/Arrow';
import { getId } from '../../../features/helpers';
import { BOT_SUPPORT_TELEGRAM_LINK, MANAGER_TELEGRAM_LINK, ROUTES } from '../../../features/constants';
import { globalStoreSelectorFilters, useGlobalStore } from '../../../features/store';


export default function HowToOrder() {
  const { setFilters } = useGlobalStore(globalStoreSelectorFilters);
  const data = useMemo(() => [
    {
      id: getId(),
      title: 'Каталог',
      text: 'выбрать из каталога',
      image: '/assets/images/background/catalog.png',
      btnText: 'Перейти',
      href: ROUTES.MAIN,
      cb: () => setFilters({ isSearching: true }),
    },
    {
      id: getId(),
      title: 'Чат-бот',
      text: 'чат-бот помощник',
      image: '/assets/images/background/chatbot.png',
      btnText: 'Написать',
      href: '',
      cb: () => {
        window.Telegram.WebApp.close();
        window.Telegram.WebApp.openTelegramLink(BOT_SUPPORT_TELEGRAM_LINK);
      },
    },
    {
      id: getId(),
      title: 'Специалист',
      text: 'консультация',
      image: '/assets/images/background/manager.jpeg',
      btnText: 'Написать',
      href: '',
      cb: () => {
        window.Telegram.WebApp.close();
        window.Telegram.WebApp.openTelegramLink(MANAGER_TELEGRAM_LINK);
      },
    },
  ], []);
  return (
    <div className="space-y-6 px-5">
      <h3 className="mt-5">Как сделать заказ? </h3>
      <div className="space-y-5">
        {data.map(({
          image, id, text, cb, title, href, btnText,
        }) => (
          <div
            key={id}
            className="relative h-150 rounded-18 bg-black overflow-hidden text-white pl-7 pt-7"
          >
            <div className="absolute inset-0 w-full h-full">
              <div className="absolute inset-0 bg-brand-overlay z-10" />
              <img
                src={image}
                width={700}
                height={500}
                alt={text}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="relative z-20 space-y-[18px]">
              <div>
                <h3>{title}</h3>
                <p className="font-light text-white">{text}</p>
              </div>
              <NavLink
                to={href}
                onClick={cb}
                className="h-8 w-fit flex items-center gap-2.5 bg-white text-black font-semibold rounded-full text-xxs px-5"
              >
                <span>{btnText}</span>
                <span>
                  <ArrowSvg />
                </span>
              </NavLink>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
