import axiosInstance from '../index';
import { ID, ProductsFilter } from '../../app/types';
import {
  PaginationRequestType,
  Product,
  ProductBrandType,
  ProductFiltersType,
  ProductModification,
} from '../types';
import { FUNCTION_RETURN_NULL } from '../../features/constants';

// export type ProductsRes = IBaseRes<{
//   data: Product[]
// }>

export const getProducts = async ({
  search = null,
  brand = null,
  size = null,
  sex = null,
  minCost = null,
  maxCost = null,
  sort = null,
  limit = 10,
  start = 0,
  isPromotion = false,
}: ProductsFilter & {
  start?: number | null;
}) => {
  try {
    const { data } = await axiosInstance.get<PaginationRequestType<Product>>(
      'products/',
      {
        params: {
          brand,
          limit,
          offset: start,
          ...(sort !== null && sort !== 'promo' && { ordering: sort }),
          search_bar: search,
          size,
          price_max: maxCost,
          price_min: minCost,
          ...(sex !== 'child' && { gender: sex }),
          ...(sex === 'child' && { group: 'Детские кросовки' }),
          promotions: isPromotion || sort === 'promo',
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getProductModification = async ({
  id,
  onSuccess = () => null,
}: {
  id: ID;
  onSuccess?: (productM: ProductModification[]) => void;
}) => {
  try {
    const { data } = await axiosInstance.get<ProductModification[]>(
      `products/${id}/modifications`,
    );
    onSuccess(data);
    return data;
  } catch (error) {
    throw error;
  }
};

export async function getFilters(onSuccess: any = FUNCTION_RETURN_NULL) {
  try {
    const { data } = await axiosInstance.get<ProductFiltersType>('/products/filters/');
    onSuccess(data);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getProductBrands() {
  try {
    const { data } = await axiosInstance.get<ProductBrandType[]>('/products/brands/');
    return data;
  } catch (e) {
    throw e;
  }
}
