/* eslint-disable no-param-reassign */

import { GlobalStoreInterface } from '../index';

export type InitialFiltersSliceTypes = {
    isOpen: boolean,
    search: string | null,
    brand: string | null,
    size: number | null | string,
    sex: string | object | null,
    minCost: string | number | null,
    maxCost: string | number | null,
    sort: string | null,
    isSearching: boolean,
    searchHistory: string[],
    isPromotion: boolean,
    limit: number | null,
}


const initialState: InitialFiltersSliceTypes = {
  isOpen: false,
  search: null,
  brand: null,
  size: null,
  sex: null,
  minCost: null,
  maxCost: null,
  sort: null,
  isSearching: false,
  searchHistory: [],
  isPromotion: false,
  limit: 10,
};

export type FiltersSliceTypes = InitialFiltersSliceTypes & {
    setFilters: (object: Partial<InitialFiltersSliceTypes>) => void;
    setFiltersInitialState: () => void;
};

export const filtersSlice = (set) => ({
  ...initialState,
  setFilters: (object) => set((state: GlobalStoreInterface) => {
    console.log('state 1321332', state);
    return {
      ...state,
      filters: {
        ...state.filters,
        ...object,
      },
    };
  }),
  setFiltersInitialState: () => set((state: GlobalStoreInterface) => ({
    ...state,
    filters: {
      ...state.filters,
      ...initialState,
    },
  })),
});
