import { useMemo, useState } from 'react';
import SortBySexes from './SortBySexes';
import SortBySizes from './SortBySizes';
import SortByCost from './SortByCost';
import SortBySort from './SortBySort';
import BrandSlider from '../../pages/main/BrandSlider';
import { globalStoreSelectorFilters, useGlobalStore } from '../../../features/store';
import { sexes, sorts } from '../../../mock/filterOptions';
import useProductsFilter from '../../../api/hooks/useProductsFilter';
import { EMPTY_ARRAY } from '../../../features/constants';

export default function SortingAndFilter() {
  const { filters, setFilters, setFiltersInitialState } = useGlobalStore(globalStoreSelectorFilters);
  const {
    isOpen: isFilterOpen, minCost, maxCost, sort, brand, size, sex,
  } = filters;
  const { data: { brands: brandsApi = [], sizes_eu: sizesEu = [], price_range: priceRange } = {} } = useProductsFilter({
    onSuccess: ({ price_range: priceRange }) => {
      if (brand === null && minCost === null && maxCost === null) {
        setFilters({
          minCost: priceRange?.min_price || 0,
          maxCost: priceRange?.max_price || 0,
        });
      }
    },
    enabled: isFilterOpen,
  });
  const brands = useMemo(() => {
    if (brandsApi.length === 0) return EMPTY_ARRAY;
    return [null, ...brandsApi];
  }, [brandsApi]);
  const sizes = useMemo(() => {
    if (sizesEu.length === 0) return EMPTY_ARRAY;
    const allElement = { value: null, label: 'Все' };
    const values = sizesEu.map((size) => ({ value: size, label: size }));
    return [allElement, ...values];
  }, [sizesEu]);
  const [startY, setStartY] = useState(0);

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    const currentY = e.touches[0].clientY;
    const deltaY = currentY - startY;

    if (deltaY > 50) {
      handleClose();
    }
  };

  const handleClose = () => {
    setFilters({ isOpen: false });
    document.body.classList.remove('overflow-hidden');
  };
  console.log('Props ', [minCost, maxCost]);
  console.log('Price range', priceRange);
  return (
    <div>
      <div
        className={`max-w-base mx-auto flex items-end inset-0 bg-brand-overlay z-40 ${
          isFilterOpen ? 'fixed' : 'hidden'
        }`}
        onClick={handleClose}
      />
      <div
        className={`fixed max-w-base h-[calc(90dvh)] border-[1px] border-[rgba(0,0,0,0.1] left-1/2 -translate-x-1/2 bottom-0 z-50 bg-white w-full transition-all duration-300 rounded-t-18 pt-3.5 ${
          isFilterOpen ? 'translate-y-0' : 'translate-y-full'
        }`}
      >
        <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
          <div className="w-14 h-0.5 mx-auto bg-brand-gray-100" />
          <h4 className="text-base font-bold text-center mt-2.5">
            Сортировка и фильтр
          </h4>
        </div>
        {/* h-[calc(80dvh)] */}
        <div className="overflow-auto pb-0 max-h-[calc(90dvh_-_24px_-_128px)]">
          <div className="space-y-4 mt-5">
            {/* BRANDS */}
            <h4 className="text-base font-bold ml-5">Бренд</h4>
            <BrandSlider brands={brands} onChange={(brand) => setFilters({ brand })} selected={brand} />
            {/* SEXES */}
            <SortBySexes
              sexes={sexes}
              onChange={(sex) => setFilters({ sex })}
              selected={sex}
            />
            {/* SIZES */}
            <SortBySizes
              sizes={sizes}
              onChange={(size) => setFilters({ size })}
              selected={size}
            />
            {/* COST */}
            <SortByCost
              value={[minCost, maxCost]}
              priceRange={[priceRange?.min_price || 0, priceRange?.max_price || 0]}
              handleMinCostChange={(minCost) => setFilters({ minCost })}
              handleMaxCostChange={(maxCost) => setFilters({ maxCost })}
              isFiltersOpen={isFilterOpen}
            />
            {/* SORT */}
            <SortBySort
              sorts={sorts}
              selected={sort}
              onChange={(sort) => setFilters({ sort })}
            />
          </div>
        </div>
        <div className="flex items-center gap-2.5 text-base text-white pb-10 font-semibold mt-12 px-5">
          <button
            onClick={setFiltersInitialState}
            className="min-w-[171px] w-full h-12 bg-brand-gray-200 rounded-full px-5"
          >
            Сбросить фильтр
          </button>
          <button
            onClick={() => setFilters({ isSearching: true, isOpen: false })}
            className="min-w-[154px] w-full h-12 bg-black rounded-full px-5"
          >
            Применить
          </button>
        </div>
        {/* BUTTONS */}
      </div>
    </div>
  );
}
