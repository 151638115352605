import { useQuery } from '@tanstack/react-query';
import { UseQueryResult } from '@tanstack/react-query/build/modern/index';
import { getBonusesInfo, getClientInfo } from '../client';
import { ClientInfoType } from '../types';
import { QUERY_KEY_CLIENT_INFO } from '../../features/constants';

export default function useClientInfo(): UseQueryResult<ClientInfoType | undefined> {
  return useQuery({
    queryKey: [QUERY_KEY_CLIENT_INFO],
    queryFn: getClientInfo,
  });
}
