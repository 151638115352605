import { NavLink, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import Button from '../../../common/Button';
import CartSvg from '../../../svg/Cart';
import Skeleton from '../../../ui/Skeleton';
import { ROUTES } from '../../../../features/constants';
import { findColorOnFront, removeDuplicates, transformSizesFromApi } from '../../../../features/helpers';
import { ColorFront, ProductSizeType } from '../../../../app/types';
import ProductSizes from '../ProductSizes';
import ProductColors from '../ProductColors';
import { ProductModification } from '../../../../api/types';
import { DetailProductStateType } from '../../../../pages/product';
import Preloader from '../../../common/Preloader';

type Types = {
  modificationsList: ProductModification[],
  state,
  setState: (object: Partial<DetailProductStateType>) => void,
  isLoading: boolean,
  isBuying: boolean,
  buy: () => void,
  handleRedirectToPayment: () => void,
  confirmUrl: string | null,
}

export default function ProductDetails({
  modificationsList = [], state, setState, isLoading = false, buy, isBuying = false, handleRedirectToPayment, confirmUrl,
}: Types) {
  const { name, id = '' } = useParams();
  const { currentModification, sizes, colors } = useMemo(() => {
    if (modificationsList.length === 0) return { currentModification: undefined, sizes: [], colors: [] };
    const { color, size } = modificationsList[0];
    const sizes: ProductSizeType[] = [];
    const colors: ColorFront[] = [];
    modificationsList.forEach((modification) => {
      sizes.push(transformSizesFromApi(modification));
      colors.push(findColorOnFront(modification));
    });
    console.log('sizes ii', sizes);
    const currentModification = modificationsList.filter((modification) => modification.color.toLocaleLowerCase() === color?.toLocaleLowerCase() && modification.size === size)?.[0];
    return {
      currentModification,
      sizes: removeDuplicates(sizes, 'size').sort(
        (a, b) => Number(a.size) - Number(b.size),
      ),
      colors: removeDuplicates(colors, 'color'),
    };
  }, [modificationsList]);
  console.log('currentModification', currentModification);
  const price = Math.round(Number(currentModification?.price_with_promo || currentModification?.price || 0));
  const sizeFormat = currentModification?.size_format;

  const renderButtons = () => {
    if (confirmUrl) {
      return (
        <Button
          className="h-[52px] flex items-center gap-4 bg-black text-white text-base font-semibold px-[30px]"
          onClick={handleRedirectToPayment}
        >
          <span>Оплатить</span>
          <CartSvg />
        </Button>
      );
    }

    return (
      <Button
        disabled={isBuying}
        className="h-[52px] flex items-center gap-4 bg-black text-white text-base font-semibold px-[30px]"
        onClick={buy}
      >
        <span>{isBuying ? <Preloader /> : 'Оформить'}</span>
        <CartSvg />
      </Button>
    );
  };

  console.log('test 1');
  return (
    <div className="bg-white rounded-18 -mt-5 pt-6 pb-10 relative z-10">
      {!isLoading ? (
        <h3 className="ml-5">
          {name}
          {' '}
          {sizeFormat?.toUpperCase()}
        </h3>
      ) : (
        <Skeleton className="h-8 w-[220px] ml-5 rounded-md" />
      )}
      {/* SIZES */}
      <div className="space-y-4 mt-5">
        <div className="flex items-center justify-between px-5">
          <div className="flex items-center gap-2.5 text-sm text-brand-gray-200">
            {/* <NavLink to="/" className="underline underline-offset-2"> */}
            {/*  Стельки */}
            {/* </NavLink> */}
            <NavLink to={ROUTES.SIZES} className="underline underline-offset-2">
              Размерная сетка
            </NavLink>
          </div>
        </div>
        <ProductSizes sizes={sizes} modificationsList={modificationsList} isLoading={isLoading} state={state} setState={setState} />
      </div>
      {/* COLORS */}
      <ProductColors
        colors={colors}
        isLoading={isLoading}
        selectedSize={state?.size}
        selectedColor={state?.color}
        setColor={(color) => setState({ color })}
      />
      {/* DESCRIPTION */}
      <div className="px-5 mt-4">
        <h4 className="text-base font-bold">Описание</h4>
        {!isLoading && (
          <p dangerouslySetInnerHTML={{ __html: currentModification?.description || '' }} />
        )}
        {isLoading && <Skeleton className="h-8 w-full rounded-md" />}
      </div>
      {/* COST */}
      <div className="flex items-center justify-between mt-8 px-5">
        <div>
          <p className="font-bold">Цена:</p>
          {!isLoading && (
          <h3>
            {price}
            {' '}
            ₽
          </h3>
          )}
          {isLoading && <Skeleton className="h-8 w-[100px] rounded-md" />}
        </div>
        {!isLoading ? (
          renderButtons()
        ) : (
          <Skeleton className="h-[52px] w-[168px] rounded-full" />
        )}
      </div>
    </div>
  );
}
