import { AnimatePresence, motion } from 'framer-motion';
import { createPortal } from 'react-dom';
import { ReactNode, useEffect, useState } from 'react';
import { FUNCTION_RETURN_NULL } from '../../../features/constants';
import { cx } from '../../../features/helpers';

type Types = {
  children: ReactNode,
  isOpen: boolean,
  portal?: boolean,
  onClose?: () => void,
  closeByOutsideClick?: boolean,
  className?: string,
  heightClassName?: string
  title?: string,
}

export default function Modal({
  children = null,
  isOpen = false,
  className = '',
  portal = true,
  onClose = FUNCTION_RETURN_NULL,
  closeByOutsideClick = true,
  title = '',
  heightClassName = 'h-4/5',
}: Types) {
  const [isDragging, setIsDragging] = useState(false);
  useEffect(() => {
    if (isOpen) document.body.classList.add('overflow-hidden');
    if (!isOpen) document.body.classList.remove('overflow-hidden'); // Это решение устраняет проблему блокировки скрола, если закрывать окно не через onClose, но создает проблему нескольких окон модальных. Пока нет варианта с несколькими модальными окнами - норм.
  }, [isOpen]);
  function handleClickOutside() {
    if (closeByOutsideClick && isOpen) onClose();
    document.body.classList.remove('overflow-hidden');
  }

  function handleDragEnd(event, info) {
    setIsDragging(false);
    // Закрыть модальное окно, если скорость перетаскивания по оси Y выше определенного значения или перетащили достаточно далеко
    if (info.velocity.y > 500 || info.point.y > 300) {
      handleClickOutside();
    }
  }
  const content = (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-40 flex items-end bg-black bg-opacity-50 d-flex justify-center"
          onClick={handleClickOutside}
        >
          <motion.div
            // initial={{ y: '100%', maxWidth: '450px' }}
            // animate={{ y: '0%' }}
            // exit={{ y: '100%' }}
            initial={{ y: '100%', maxWidth: '450px' }}
            animate={{ y: isDragging ? '15%' : '0%' }} // Динамическое изменение позиции для анимации "тяги"
            exit={{ y: '100%' }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }} // Настройки анимации для плавности
            className={cx(
              'fixed bottom-0 w-full bg-white rounded-t-3xl pb-4 overflow-y-auto relative',
              heightClassName && heightClassName,
              className && className,
            )}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="sticky top-0 bg-white z-50 pt-4 px-4 ">
              <motion.div
                className="h-5  bg-none absolute left-0 right-0 top-0 w-full"
                drag="y"
                dragConstraints={{ top: 0, bottom: 0 }}
                onDragStart={() => setIsDragging(true)}
                onDragEnd={handleDragEnd}
              />
              <div
                className="w-14 h-0.5 mx-auto bg-brand-gray-100"
              />
              <h4 className="text-base font-bold text-center mt-2.5">
                {title}
              </h4>
            </div>
            <div className="px-4">
              {children}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return portal
    ? createPortal(content, document.body)
    : content;
}
