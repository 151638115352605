export default function About() {
  return (
    <div className="p-2.5">
      <p>
        — Быстрая доставка по всей России
        <br />
        <br />
        — Цены от 6тыс
        <br />
        <br />
        — Подарки к каждому заказу
        <br />
        <br />
        — Гарантия на товар ( обмен/возврат)
        <br />
        <br />
        — Лучшее качество на рынке
        <br />
        <br />
        — Дополнительная упаковка для транспортировки
        <br />
        <br />
        — Все размеры всегда в наличии
        <br />
        <br />
        — Личный менеджер 24/7
        <br />
        <br />
        — Бонусы, скидки при повторном заказе
        <br />
        <br />
        — Программа лояльности «Приведи друга»
        <br />
        <br />
        — Два онлайн магазина:
        <br />
        Адрес магазина в Одинцово:
        Ул. Говорова 163, ТЦ &quot;Атлас&quot;
        <br />
        Адрес магазина в г.Наро-Фоминск:
        Ул, площадь. Свободы д.4 под-2 помещение 11
        <br />
        <br />
        — Режим работы с 10:00-22:00 ежедневно.
      </p>
    </div>
  );
}
