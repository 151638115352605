import { cx } from '../../../../features/helpers';
import useDraggingScroll from '../../../../features/hooks/useDraggingScroll';

export default function SortBySexes({
  onChange,
  sexes,
  selected,
}) {
  const {
    onMouseUp, onMouseMove, onMouseDown, isDragging, ignoreNextClick,
  } = useDraggingScroll();
  return (
    <div className="space-y-4">
      <h4 className="text-base font-bold ml-5">Подходят для</h4>
      <div
        className="scroll-none flex flex-nowrap overflow-auto gap-2.5"
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseUp}
        onMouseMove={onMouseMove}
      >
        {sexes.map((sex, index) => (
          <button
            key={sex?.id}
            onClick={() => {
              if (isDragging || ignoreNextClick) return;
              onChange(sex?.value);
            }}
            className={cx(
              'h-7 flex items-center text-sm font-semibold text-brand-gray-200 whitespace-nowrap px-3',
              index === 0 && 'ml-5',
              index === sexes.length - 1 && 'mr-5',
              selected === sex?.value && 'bg-brand-red rounded-full text-white border-brand-red',
            )}
          >
            {sex.label}
          </button>
        ))}
      </div>
    </div>
  );
}
