import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { checkSetPhoneNumber } from '../client';
import { QUERY_KEY_CHECK_VERIFIED } from '../../features/constants';
import { CheckVerifiedResType } from '../types';

export default function useVerifyPhone(): UseQueryResult<CheckVerifiedResType, unknown> {
  return useQuery({
    queryKey: [QUERY_KEY_CHECK_VERIFIED],
    queryFn: checkSetPhoneNumber,
    enabled: false,
  });
}
