import axiosInstance from '../index';
import { CreatePaymentResponseType, PostOrderRequestType, PostOrderResponseType } from './types';

export async function postOrder(object: PostOrderRequestType) {
  try {
    const { data } = await axiosInstance.post<PostOrderResponseType>('/orders/', {
      ...object,
    });
    return data;
  } catch (e) {
    throw e;
  }
}


export async function createPayment(id: string) {
  try {
    const { data } = await axiosInstance.post<CreatePaymentResponseType>(`/orders/${id}/create-payment/`);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getOrders() {
  try {
    const { data } = await axiosInstance.get<PostOrderResponseType>('/orders');
    return data;
  } catch (e) {
    throw e;
  }
}
