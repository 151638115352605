export default function SkeletonList() {
  const list = [...Array(4)].map((u, i) => i);
  return (
    <>
      {list.map((index) => (
        <div className="animate-pulse space-y-2.5" key={index}>
          <div className="h-40 bg-brand-gray-100 rounded-18" />
          <div className="text-base">
            <div className="font-bold h-4 rounded-18 bg-brand-gray-100" />
            <div className="font-normal h-4 bg-brand-gray-100 rounded-18 brand-black text-black mt-2" />
          </div>
        </div>
      ))}
    </>
  );
}
