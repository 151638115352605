import useProductBrands from '../../../../api/hooks/useProductBrands';
import BrandCard from './Card';

export default function ProductBrands() {
  const { data, isLoading } = useProductBrands();

  return (
    <div className="grid grid-cols-3 gap-2.5 pt-5">
      {!isLoading && data?.map((brand) => <BrandCard key={brand.title} brand={brand} />)}
    </div>
  );
}
