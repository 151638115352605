import axiosInstance from '../index';
import { PromoType } from '../types';

export async function getPromotions() {
  try {
    const { data } = await axiosInstance.get<PromoType[] | undefined>('/promotions/');
    return data;
  } catch (e) {
    throw e;
  }
}
