import { ProductSizeType } from '../../../../../app/types';
import { cx } from '../../../../../features/helpers';
import { DetailProductStateType } from '../../../../../pages/product';
import { ProductModification } from '../../../../../api/types';

interface ProductSizeProps {
  productSize: ProductSizeType;
  setState: (object: Partial<DetailProductStateType>) => void,
  state: DetailProductStateType,
  modificationsList: ProductModification[]
}

export default function ProductSize({
  productSize,
  setState,
  state,
  modificationsList = [],
}: ProductSizeProps) {
  const activeSizeId = state.id;
  const findCurrentModification = (id: string) => {
    const modification = modificationsList.find((modification) => modification.id === id) ?? null;
    if (modification) {
      const {
        id, price, price_with_promo, color, size,
      } = modification;
      setState({
        id,
        price: price_with_promo || price,
        count: 1,
        color,
        size,
      });
    }
  };
  const isSelectedSize = productSize.id === activeSizeId;
  return (
    <div
      onClick={() => findCurrentModification(productSize.id)}
      className={cx(
        'flex flex-col text-center text-sm cursor-pointer font-semibold text-brand-gray-200 whitespace-nowrap px-2.5 py-0.5',
        isSelectedSize && 'bg-brand-red rounded-full border-brand-red',
      )}
    >
      <span
        className={cx(
          'text-sm font-semibold mx-auto',
          isSelectedSize ? 'text-white' : 'text-black',
        )}
      >
        {productSize.size}
      </span>
      <span
        className={cx('text-xs font-normal', isSelectedSize ? 'text-white' : 'text-brand-gray-200')}
      >
        {productSize.size}
        {' '}
        см
      </span>
    </div>
  );
}
