import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { PromoType } from '../types';
import { QUERY_KEY_PROMOTIONS } from '../../features/constants';
import { getPromotions } from '../promotions';

export default function usePromotions(
  queryOption = {},
): UseQueryResult<PromoType[] | undefined> {
  return useQuery({
    queryKey: [QUERY_KEY_PROMOTIONS],
    queryFn: getPromotions,
    ...queryOption,
  });
}
