import SearchingNumberOfResults from '../ProductNotFound/SearchingNumberOfResults';
import { globalStoreSelectorFilters, useGlobalStore } from '../../../features/store';
import { toReactKeyString } from '../../../features/helpers';

export default function SearchHistory({ productsCount = 0 }) {
  const { filters, setFilters } = useGlobalStore(globalStoreSelectorFilters);
  const { search, searchHistory } = filters;
  return (
    <div className="space-y-5 mt-5">
      <SearchingNumberOfResults
        updatedProductsNumber={productsCount}
      />
      <div>
        <div className="flex flex-wrap items-center gap-2.5">
          {searchHistory.map((string, index) => (
            <button
              key={toReactKeyString(string)}
              className="h-8 bg-brand-gray-100 rounded-full px-3"
              onClick={() => setFilters({ search: string })}
            >
              {string}
            </button>
          ))}
          {searchHistory.length !== 0 && (
          <button onClick={() => setFilters({ searchHistory: [] })} className="h-8 bg-brand-red text-white rounded-xl px-3">
            Очистить историю
          </button>
          )}
        </div>
      </div>
    </div>
  );
}
