import Skeleton from '../../../ui/Skeleton';
import ProductSize from './ProductSize';
import { ProductSizeType } from '../../../../app/types';
import { DetailProductStateType } from '../../../../pages/product';
import { ProductModification } from '../../../../api/types';


type Types = {
    sizes: ProductSizeType[],
    isLoading?: boolean,
    setState: (object: Partial<DetailProductStateType>) => void,
    state: DetailProductStateType,
    modificationsList: ProductModification[],
}

export default function ProductSizes({
  sizes = [], isLoading = false, state, setState,
  modificationsList = [],
} : Types) {
  return (
    <div
      className={`scroll-none flex flex-nowrap overflow-auto gap-2.5 ${
        !isLoading && 'px-[10px]'
      }`}
    >
      {!isLoading && sizes?.map((item) => (
        <ProductSize
          state={state}
          modificationsList={modificationsList}
          setState={setState}
          key={item?.id}
          productSize={item}
        />
      ))}
      {isLoading && (
        <>
          <Skeleton className="h-[40px] w-[50px] rounded-full ml-5" />
          <Skeleton className="h-[40px] w-[50px] rounded-full" />
          <Skeleton className="h-[40px] w-[50px] rounded-full" />
          <Skeleton className="h-[40px] w-[50px] rounded-full" />
          <Skeleton className="h-[40px] w-[50px] rounded-full" />
        </>
      )}
    </div>
  );
}
