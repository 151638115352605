import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { Product } from '../../../../../api/types';
import { NO_IMAGE_LINK } from '../../../../../features/constants';

export default function ProductCard({ product }: { product: Product }) {
  const [error, setError] = useState(!product?.mediafiles[0]?.media);
  const productName = product.title;
  const price = product.minimal_price_with_promo
    ? product.minimal_price_with_promo
    : product.minimal_price;
  const image = product.mediafiles[0]?.media || NO_IMAGE_LINK;

  return (
    <NavLink
      // onClick={() => {
      //   handleAddSearchedTerm();
      //   setSearchTerm("");
      // }}
      to={`/products/${product.id}/${productName}`}
      className="space-y-2.5"
    >
      <div
        className={[
          'h-40 rounded-18',
          product.mediafiles.length > 0 ? '' : 'bg-brand-gray-100',
        ].join(' ')}
      >
        <img
          src={error ? NO_IMAGE_LINK : image}
          onError={() => {
            setError(true);
          }}
          alt={productName}
          className="w-full h-full object-contain"
        />
      </div>
      <div className="text-base flex leading-none flex-col gap-[5px]">
        <h4 className="font-bold">{productName}</h4>
        <p className="font-normal brand-black text-black">
          {Math.round(Number(price))}
          {' '}
          <span>₽</span>
        </p>
      </div>
    </NavLink>
  );
}
