import { NavLink } from 'react-router-dom';
import ArrowSvg from '../../../svg/Arrow';
import { ROUTES } from '../../../../features/constants';
import useBonusesInfo from '../../../../api/hooks/useBonusesInfo';

export default function SelectAndOrder() {
  const { data: bonusesInfo } = useBonusesInfo();
  const bonuses = bonusesInfo?.bonuses || 0;
  return (
    <div className="grid grid-cols-2 gap-y-2.5 gap-x-5 mt-5 bg-no-repeat bg-cover bg-bottom rounded-18">
      {/* Выбрать и заказать */}
      <NavLink
        to={ROUTES.HOW_TO_ORDER}
        className="border h-40 pt-[10px] pr-[9px] gap-[13px] flex-col col-span-2 relative flex items-center bg-block-1 bg-cover bg-no-repeat rounded-18 overflow-hidden"
      >
        {/* <span className="w-8 h-8 absolute top-2.5 right-2 grid place-content-center rounded-full overflow-hidden bg-brand-red">
          <ArrowSvg color="#fff" />
        </span> */}
        <div className="w-full flex justify-end">
          <span className="bg-brand-red rounded-full w-8 h-8 grid place-content-center text-right">
            <ArrowSvg color="#fff" />
          </span>
        </div>
        <div className="text-white space-y-0">
          <h2 className=" text-3xl font-semibold leading-none">Выбрать и заказать</h2>
          <p className="font-light text-white leading-none text-xs">Как сделать заказ?</p>
        </div>
      </NavLink>
      {/* Баллов */}
      <NavLink
        to={ROUTES.BONUS}
        className="border h-40 relative flex relative items-end justify-center bg-block-2 bg-cover bg-no-repeat rounded-18 overflow-hidden pb-8"
      >
        <span className="w-8 h-8 absolute top-2.5 right-2 grid place-content-center rounded-full overflow-hidden bg-brand-red">
          <ArrowSvg color="#fff" />
        </span>
        <div className="pr-[9px] text-white space-y-0 absolute top-[55px]">
          <h2 className="text-3xl font-semibold text-white leading-none">{bonuses}</h2>
          <div className="">
            <p className="text-white  text-xs">Баллов</p>
            <p className="text-white text-xs mt-[10px]">Баллов 1 балл = 1 ₽</p>
          </div>
        </div>
      </NavLink>
      {/* Приглашай друзей */}
      <NavLink
        to={ROUTES.REFERRAL}
        className="border h-40 relative flex items-end justify-center bg-block-3 bg-cover bg-no-repeat rounded-18 overflow-hidden pb-8"
      >
        <span className="w-8 h-8 absolute top-2.5 right-2 grid place-content-center rounded-full overflow-hidden bg-brand-red">
          <ArrowSvg color="#fff" />
        </span>
        <div className="pl-[20px] pr-[9px] text-white space-y-0">
          <h2 className=" text-3xl font-semibold text-white leading-none">
            500
            {' '}
            <span>₽</span>
          </h2>
          <p className="font-light text-white leading-none text-xs">
            Приглашай друзей!
            {' '}
            <br />
            Дарим по 500 рублей каждому!
          </p>
        </div>
      </NavLink>
    </div>
  );
}
