import { useState } from 'react';

export default function useDraggingScroll() {
  const [scrollState, setScrollState] = useState({
    isDragging: false,
    startX: 0,
    scrollStartX: 0,
    draggedDistance: 0,
    ignoreNextClick: false,
  });

  const onMouseDown = (e) => {
    setScrollState({
      isDragging: true,
      startX: e.pageX,
      scrollStartX: e.currentTarget.scrollLeft,
      draggedDistance: 0,
      ignoreNextClick: true,
    });
  };

  const onMouseMove = (e) => {
    if (!scrollState.isDragging) return;
    const diff = scrollState.startX - e.pageX;
    // Обновляем состояние с новым значением перемещения
    setScrollState((prev) => ({
      ...prev,
      draggedDistance: prev.draggedDistance + Math.abs(diff),
    }));
    e.currentTarget.scrollLeft = scrollState.scrollStartX + diff;
  };

  const onMouseUp = () => {
    const wasDragging = scrollState.draggedDistance > 5; // Пороговое значение
    setScrollState((prev) => ({
      ...prev,
      isDragging: false,
      draggedDistance: 0,
      ignoreNextClick: wasDragging, // Игнорировать следующий клик, если было перетаскивание
    }));
    if (wasDragging) {
      setTimeout(() => {
        setScrollState((prev) => ({ ...prev, ignoreNextClick: false })); // Сбросить флаг с задержкой
      }, 100); // Задержка для сброса флага
    }
  };

  return {
    ...scrollState, onMouseDown, onMouseMove, onMouseUp, onMouseLeave: onMouseUp,
  };
}
