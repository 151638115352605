import axiosInstance from '../index';
import {
  BonusesInfoType,
  ClientInfoType,
  EditDeliveryInfoResponseType,
  ReferalInfoRes,
  SetPhoneResponseType,
  VerifyPhoneResponseType,
} from '../types';

export const getClientReferalInfo = async () => {
  try {
    const { data } = await axiosInstance.get<ReferalInfoRes | undefined>(
      'client/referal_info/',
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export async function getBonusesInfo() {
  try {
    const { data } = await axiosInstance.get<BonusesInfoType | undefined>(
      '/client/bonuses_info/',
    );
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getClientInfo() {
  try {
    const { data } = await axiosInstance.get<ClientInfoType | undefined>('/client/info/');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updatePhone(phone: string) {
  try {
    const { data } = await axiosInstance.put<SetPhoneResponseType | undefined>(
      '/client/set_phone_number/',
      {
        phone,
      },
    );
    return data;
  } catch (e) {
    throw e;
  }
}

export async function checkSetPhoneNumber() {
  try {
    const { data } = await axiosInstance.get(
      '/client/set_phone_number/check/',
    );
    return data;
  } catch (e) {
    throw e;
  }
}

export async function verifyPhone(code: string) {
  try {
    const { data } = await axiosInstance.patch<VerifyPhoneResponseType | undefined>(
      '/client/verify_phone_number/',
      {
        code,
      },
    );
    return data;
  } catch (e) {
    throw e;
  }
}

export async function editDeliveryInfo({
  firstname,
  lastname,
  phone,
  secondname,
  address,
}: {
  firstname: string;
  lastname: string;
  secondname: string;
  phone: string;
  address: string;
}) {
  try {
    const { data } = await axiosInstance.put<EditDeliveryInfoResponseType | undefined>(
      '/client/delivery_info/',
      {
        first_name: firstname,
        last_name: lastname,
        second_name: secondname,
        phone,
        address,
      },
    );
    return data;
  } catch (e) {
    throw e;
  }
}

export async function createDeliveryInfo({
  firstname,
  lastname,
  phone,
  secondname,
  address,
}: {
  firstname: string;
  lastname: string;
  secondname: string;
  phone: string;
  address: string;
}) {
  try {
    const { data } = await axiosInstance.post<EditDeliveryInfoResponseType | undefined>(
      '/client/delivery_info/',
      {
        first_name: firstname,
        last_name: lastname,
        second_name: secondname,
        phone,
        address,
      },
    );
    return data;
  } catch (e) {
    throw e;
  }
}
