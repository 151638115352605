import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBack from '../../svg/ArrowBack';
import { globalStoreSelectorFilters, useGlobalStore } from '../../../features/store';

export default function AppNav() {
  const { filters, setFilters, setFiltersInitialState } = useGlobalStore(globalStoreSelectorFilters);
  const { isSearching } = filters;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <nav className="relative flex justify-center items-center bg-white py-3 px-5">
      {pathname === '/' && !isSearching ? null : pathname === '/'
        && isSearching ? (
          <button
            onClick={setFiltersInitialState}
            className="absolute bottom-5 left-5"
          >
            <ArrowBack />
          </button>
        ) : (
          <button onClick={() => navigate(-1)} className="absolute bottom-5 left-5">
            <ArrowBack />
          </button>
        )}
      <a href="/">
        <img
          src="/assets/images/logo.svg"
          alt="Logo"
          className="w-[181px] h-11 mx-auto"
        />
      </a>
    </nav>
  );
}
