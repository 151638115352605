import { globalStoreSelectorFilters, useGlobalStore } from '../../../../features/store';

export default function SearchingNumberOfResults({
  updatedProductsNumber,
}) {
  const { filters } = useGlobalStore(globalStoreSelectorFilters);
  const { search } = filters;
  return (
    <div className="">
      {search && (
        <div className="flex items-center justify-between font-bold">
          <h4>
            Поиск “
            <span>{search}</span>
            ”
          </h4>
          <h4>
            {updatedProductsNumber}
            {' '}
            найдено
          </h4>
        </div>
      )}
    </div>
  );
}
