import { useCallback, useState } from 'react';
import debounce from 'lodash.debounce';
import { globalStoreSelectorFilters, useGlobalStore } from '../../../features/store';

export default function SearchInput() {
  const [input, setInput] = useState<string>('');
  const { filters, setFilters } = useGlobalStore(globalStoreSelectorFilters);
  const { searchHistory } = filters;
  const debouncedSetSearch = useCallback(debounce((string = '') => {
    setFilters({ search: string });
  }, 500), []);

  function handleChangeInput(e) {
    const { value } = e.target;
    setInput(value);
    debouncedSetSearch(value);
  }

  console.log('filters', filters);

  return (
    <div className="h-11 flex items-center gap-3 rounded-[10px] search-input-shadow pl-5 pr-[18px]">
      <input
        type="text"
        placeholder="Найди свои кроссовки"
        className="w-full text-xs placeholder:text-brand-gray-200 placeholder:font-normal font-medium outline-none leading-normal"
        value={input}
        onFocus={() => setFilters({ isSearching: true })}
        onChange={handleChangeInput}
      />
      <button
        onClick={() => setFilters({ isOpen: true })}
      >
        <img
          src="/assets/images/icons/filter.svg"
          alt="filter icon"
          className="w-[19px]"
        />
      </button>
    </div>
  );
}
