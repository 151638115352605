import { ReactNode } from 'react';

interface ButtonProps {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  disabled?: boolean
}

export default function Button({
  children, className, onClick, disabled = false,
}: ButtonProps) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`flex items-center justify-center gap-4 font-semibold rounded-full ${className}`}
    >
      {children}
    </button>
  );
}
