import { useQuery } from '@tanstack/react-query';
import { UseQueryResult } from '@tanstack/react-query/build/modern/index';
import { BonusesResponseType } from '../types';
import { QUERY_KEY_BONUSES } from '../../features/constants';
import { getBonuses } from '../bonuses';

export default function useBonuses(): UseQueryResult<BonusesResponseType | undefined> {
  return useQuery({
    queryKey: [QUERY_KEY_BONUSES],
    queryFn: getBonuses,
  });
}
