import { OrdersResponseType } from '../../../../api/orders/types';
import { NO_IMAGE_LINK } from '../../../../features/constants';
import Button from '../../../common/Button';

export default function OrderCard({ order } : {order: OrdersResponseType}) {
  const { items, status, payment } = order;
  if (items.length === 0) return null;
  const [item] = items;
  const {
    item: {
      mediafile = '', size, title, color,
    }, amount,
  } = item;
  const confirmation_url = payment?.yookassa?.confirmation_url;

  const handleClickBuy = () => {
    window.open(confirmation_url, '_blank');
  };

  return (
    <div className="flex flex-col border max-w-[320px] border-black/10 rounded-18 pb-2.5 px-2.5 pt-2.5">
      <img src={mediafile || NO_IMAGE_LINK} alt="" />
      <p className="font-bold text-black overflow-ellipsis whitespace-nowrap overflow-hidden">
        {title}
      </p>
      <div className="flex">
        <p className="font-bold mr-2">
          Размер:
        </p>
        <p>
          {size}
        </p>
      </div>
      <div className="flex">
        <p className="font-bold mr-2">
          Цвет:
        </p>
        <p>
          {color}
        </p>
      </div>
      <div className="flex mt-2">
        <p className="font-bold mr-2">
          Статус:
        </p>
        <p className="text-black text-black font-bold">
          {status}
        </p>
      </div>
      {status === 'Ожидает оплаты'
            && (
            <Button
              className="flex items-center justify-center gap-4 font-semibold rounded-full h-8 bg-black whitespace-nowrap text-white px-5 py-2.5 mt-2 text-base"
              onClick={handleClickBuy}
            >
              Оплатить
            </Button>
            )}
    </div>
  );
}
