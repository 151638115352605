import { useQuery } from '@tanstack/react-query';
import { UseQueryResult } from '@tanstack/react-query/build/modern/index';
import { getBonusesInfo } from '../client';
import { BonusesInfoType, ClientInfoType } from '../types';
import { QUERY_KEY_BONUSES_INFO } from '../../features/constants';

export default function useBonusesInfo(): UseQueryResult<BonusesInfoType | undefined> {
  return useQuery({
    queryKey: [QUERY_KEY_BONUSES_INFO],
    queryFn: getBonusesInfo,
  });
}
