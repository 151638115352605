import { useEffect, useMemo } from 'react';
import ProductNotFound from '../../common/ProductNotFound';
import SearchHistory from '../../common/SearchHistory';
import SearchInput from '../../ui/SearchInput';
import InfoCards from './InfoCards';
import MainProductSlider from './MainProductSlider';
import ProductsList from './ProductsList';
import SelectAndOrder from './SelectAndOrder';
import SortingAndFilter from '../../ui/Filter';
import { globalStoreSelectorFilters, useGlobalStore } from '../../../features/store';
import useProductsInfinity from '../../../api/hooks/useProductsInfinity';
import useInfinityScroll from '../../../features/hooks/useInfinityScroll';
import { EMPTY_ARRAY } from '../../../features/constants';
import { Product } from '../../../api/types';
import ProductBrands from './ProductBrands';

export default function Home() {
  const { filters, setFilters } = useGlobalStore(globalStoreSelectorFilters);
  const {
    isSearching, searchHistory, isOpen, ...rest
  } = filters;
  const {
    data: productsInfinity, fetchNextPage, isPending: isInfinityLoading, hasNextPage,
  } = useProductsInfinity({
    filter: rest,
    options: {
      enabled: !isOpen,
    },
  });
  const count = productsInfinity?.pages[0]?.count || 0;
  const nextRef = useInfinityScroll({
    cb: fetchNextPage,
    disabled: isInfinityLoading || !hasNextPage || !productsInfinity,
  });
  const products = useMemo(() => {
    if (!productsInfinity) return EMPTY_ARRAY;

    const arr: Product[] = [];
    const duplicates: any = {};

    productsInfinity?.pages.forEach((page) => {
      page.results.forEach((element) => {
        if (duplicates?.[element.id]) {
          duplicates[element.id] = [...duplicates[element?.id], element];
        } else {
          duplicates[element.id] = [element];
        }

        arr.push(element);
      });
    });
    return arr;
  }, [productsInfinity]);

  useEffect(() => {
    if (count !== 0 && filters.search) {
      setFilters({ searchHistory: [...searchHistory, filters.search] });
    }
  }, [count !== 0, filters.search]);

  return (
    <div className="pb-10">
      <div className="px-5">
        <SearchInput />
        <SortingAndFilter />
        {isSearching && !isInfinityLoading && count === 0 && <ProductNotFound />}
        {isSearching && !isInfinityLoading && count !== 0 && <SearchHistory productsCount={count} />}
        {!isSearching && (
        <div>
          <ProductBrands />
          <InfoCards />
          <SelectAndOrder />
          <MainProductSlider />
        </div>
        )}
      </div>
      <ProductsList products={products} isLoading={isInfinityLoading} reachEnd={!hasNextPage} />
      <div ref={nextRef} />
    </div>
  );
}
