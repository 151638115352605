import Modal from '../../Modal';
import Button from '../../Button';

export default function BonusesModal({ isOpen, onClose, buy }) {
  return (
    <Modal title="Использовать бонусы?" isOpen={isOpen} onClose={onClose} heightClassName="h-1/4">
      <div className="grid grid-cols-2 gap-1 mt-4">
        <Button
          className="flex items-center justify-center gap-4 font-semibold rounded-full h-[52px] flex items-center gap-4 bg-black text-white text-base font-semibold px-[30px]"
          onClick={() => {
            buy(true);
            onClose();
          }}
        >
          Использовать
        </Button>
        <Button
          className="flex items-center justify-center gap-4 font-semibold rounded-full h-[52px] flex items-center gap-4 bg-black text-white text-base font-semibold px-[30px]"
          onClick={() => {
            buy(false);
            onClose();
          }}
        >
          Не использовать
        </Button>
      </div>
    </Modal>
  );
}
