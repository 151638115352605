import { useParams } from 'react-router-dom';
import ConfirmSvg from '../../../svg/Confirm';
import Skeleton from '../../../ui/Skeleton';
import useProductModification from '../../../../api/hooks/useProductModification';
import { ColorFront } from '../../../../app/types';

type Types = {
    colors: ColorFront[],
    isLoading: boolean,
    selectedSize?: string | undefined,
    selectedColor?: string | undefined,
    setColor?: (str: string) => void;
 }

export default function ProductColors({
  colors = [], isLoading = false, selectedSize, selectedColor,
  setColor,
}: Types) {
  const { id = '' } = useParams();
  const { data: allModifications = [] } = useProductModification({ id });

  function checkForColorUsing(color: string) {
    const modificationsBySize = allModifications.filter(
      (modification) => modification.size === selectedSize,
    );
    const canBeUsedColor = modificationsBySize.find(
      (modification) => modification.color.toLocaleLowerCase() === color.toLocaleLowerCase(),
    );
    return Boolean(canBeUsedColor);
  }

  const setColorHandler = (color: string, canUsed: boolean) => {
    if (!canUsed || !setColor) {
      return;
    }
    setColor(color);
  };
  console.log('colors', colors);

  return (
    <div className="space-y-4 mt-5">
      <h4 className="text-base font-bold ml-5">Цвет</h4>
      <div className="scroll-none flex flex-nowrap overflow-auto gap-2.5 px-5">
        {!isLoading
          && colors.map(({ color, hex, isMultiColor }, index) => {
            const canBeUsed = checkForColorUsing(color);
            const [hex1, hex2] = hex.split(':');
            return (
              <div
                onClick={() => setColorHandler(color, canBeUsed)}
                key={index}
                className={`w-[27px] h-[27px] border-2 border-neutral-700 grid place-content-center rounded-full ${canBeUsed && 'cursor-pointer'} relative`}
                style={{
                  boxShadow: '1px 1px 10px 0px rgba(34, 39, 79, 0.10)',
                  filter: !canBeUsed ? 'opacity(15%)' : 'blur(0)',
                }}
              >
                {!isMultiColor ? (
                  <div
                    className="absolute left-0 top-0 w-full h-full rounded-full"
                    style={{ backgroundColor: hex }}
                  />
                ) : (
                  <>
                    <div
                      className="absolute left-0 top-0 w-1/2 h-full rounded-l-full"
                      style={{ backgroundColor: hex1 }} // замените на нужный цвет
                    />
                    <div
                      className="absolute right-0 top-0 w-1/2 h-full rounded-r-full"
                      style={{ backgroundColor: hex2 }} // замените на нужный цвет
                    />
                  </>
                )}
                {canBeUsed
                  && selectedColor?.toLocaleLowerCase()
                    === color.toLocaleLowerCase() && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <ConfirmSvg color={hex} />
                    </div>
                )}
              </div>
            );
          })}
        {isLoading && (
          <>
            <Skeleton className="h-[27px] w-[27px] rounded-full" />
            <Skeleton className="h-[27px] w-[27px] rounded-full" />
            <Skeleton className="h-[27px] w-[27px] rounded-full" />
            <Skeleton className="h-[27px] w-[27px] rounded-full" />
          </>
        )}
      </div>
    </div>
  );
}
