// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';
import { useMemo } from 'react';
import { Mediafiles } from '../../../../api/types';
import { NO_IMAGE_LINK } from '../../../../features/constants';

type Types = {
    isLoading: boolean,
    images: Mediafiles | undefined,
}

export default function ProductDetailsSlider({ images = [], isLoading = false }: Types) {
  const slides = useMemo(() => images?.map((image, index) => (
    <SwiperSlide key={index}>
      <img
        src={image.media || NO_IMAGE_LINK}
        alt={image.media}
          // width={1000}
          // height={500}
        className="w-full h-[100%] object-contain"
      />
    </SwiperSlide>
  )), [images]);

  console.log('images sw', images);
  return (
    <div>
      <div
        className={`h-72 xs:h-80 relative ${
          isLoading && 'animate-pulse'
        } p-5`}
      >
        {images?.length === 0 && (
          <img
            src={NO_IMAGE_LINK}
            alt=""
            className="w-full h-[100%] object-contain"
          />
        )}
        {!isLoading && (
          <Swiper
            pagination
            speed={300}
            loop={images?.length > 1}
            modules={[Pagination]}
            className="mySwiper2 details-slider h-[100%]"
          >
            {slides}
          </Swiper>
        )}
      </div>
    </div>
  );
}
