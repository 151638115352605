import axiosInstance from '../index';
import { BonusesResponseType } from '../types';

export async function getBonuses() {
  try {
    const { data } = await axiosInstance.get<BonusesResponseType>('bonuses/bonuses');
    return data;
  } catch (e) {
    throw e;
  }
}
