import Home from '../../components/pages/main';

export default function MainPage() {
  return (
    <div className="pb-10">
      <Home />
    </div>
  );
}


