import { useRef, useState } from 'react';
import Button from '../../common/Button';
import BigConfirmSvg from '../../svg/BigConfirm';
import DocumentSvg from '../../svg/Document';
import useReferalInfo from '../../../api/hooks/useReferalInfo';
import ReferalCounters from './ReferalCounters';
import Skeleton from '../../ui/Skeleton';

export default function Referral() {
  const { data, isLoading } = useReferalInfo();
  const referalLink = data?.referal_link || '';
  // console.log(data);
  const [copyMessage, setCopyMessage] = useState('');
  // const referalInfo = {
  //   invited_clients_count: data?.invited_clients_count ?? 0,
  //   orders_info_by_invited_clients: data?.orders_info_by_invited_clients,
  // };
  const textAreaRef = useRef(null);

  const handleCopyLink = () => {
    if (textAreaRef.current) {
      // @ts-ignore
      textAreaRef.current.select();
      document.execCommand('copy');
      setCopyMessage('Ссылка скопирована!');
    }
  };
  return (
    <div className="">
      <img
        src="/assets/images/legs.png"
        alt="Legs"
        width={1000}
        height={1400}
        className="h-80 w-full"
      />
      <div className="relative divide-y divide-brand-gray-100 z-10 bg-white rounded-t-18 pt-6 -mt-5 px-5">
        <div className="space-y-2 pb-9">
          <h3>
            Приглашай друзей! Дарим
            {' '}
            <br />
            {' '}
            по
            <span className="text-brand-red ml-2">500 рублей</span>
            {' '}
            каждому!
          </h3>
          <div className="space-y-4">
            <p className="leading-tight">
              Получи за каждое приглашение по твоей реферальной ссылке 500
              баллов. Также 500 баллов получит твой друг.
            </p>
          </div>
        </div>
        <div className="space-y-5 py-4">
          <div>
            <h5 className="text-base font-bold">Ссылка на приглашение</h5>
            {!isLoading ? (
              <a
                href={referalLink}
                className="text-sm text-brand-red underline underline-offset-4"
              >
                <textarea
                  ref={textAreaRef}
                  value={referalLink}
                  style={{ position: 'absolute', left: '-9999px' }}
                  readOnly
                />
                {referalLink}
              </a>
            ) : (
              <Skeleton className="h-[15px] w-full rounded-md" />
            )}
          </div>
          <Button
            onClick={() => {
              handleCopyLink();
              setTimeout(() => {
                setCopyMessage('');
              }, 2000);
            }}
            className={`h-12 flex items-center gap-2.5 text-white text-base px-5 py-4 ${
              copyMessage ? 'bg-brand-red' : 'bg-black'
            }`}
          >
            {copyMessage ? (
              <span className="flex items-center gap-2.5">
                {copyMessage}
                {' '}
                <span>
                  <BigConfirmSvg />
                </span>
              </span>
            ) : (
              <>
                Скопировать ссылку
                <span>
                  <DocumentSvg />
                </span>
              </>
            )}
          </Button>
        </div>
        <ReferalCounters referalInfo={data} isLoading={isLoading} />
      </div>
    </div>
  );
}
