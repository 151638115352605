import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../../features/constants';
import MainPage from '../../../pages/main';
import SizesPage from '../../../components/pages/sizes';
import ReferalPage from '../../../pages/referral';
import HowToOrderPage from '../../../pages/how-to-order';
import BonusPage from '../../../pages/bonus';
import UserPage from '../../../pages/user';
import ProductPage from '../../../pages/product';
import About from '../../../pages/about';
import Orders from '../../../pages/orders';

export function AppRouter() {
  return (
    <Suspense fallback={<>Loading...</>}>
      <Routes>
        <Route path={ROUTES.MAIN} index element={<MainPage />} />
        <Route path={ROUTES.SIZES} element={<SizesPage />} />
        <Route path={ROUTES.REFERRAL} element={<ReferalPage />} />
        <Route path={ROUTES.HOW_TO_ORDER} element={<HowToOrderPage />} />
        <Route path={ROUTES.BONUS} element={<BonusPage />} />
        <Route path={ROUTES.USER} element={<UserPage />} />
        <Route path={ROUTES.PRODUCT_DETAIL} element={<ProductPage />} />
        <Route path={ROUTES.ABOUT} element={<About />} />
        <Route path={ROUTES.ORDERS} element={<Orders />} />
        <Route path={'/*'} element={null} />
      </Routes>
    </Suspense>
  );
}
