import { cx } from '../../../../features/helpers';
import useDraggingScroll from '../../../../features/hooks/useDraggingScroll';

type Types = {
    onChange: (brand: number | null | string) => void,
    sizes: {value: string | number | null, label: number | string}[],
    selected: number | null | string,
}

export default function SortBySizes({
  sizes = [],
  selected,
  onChange,
}: Types) {
  const {
    onMouseUp, onMouseMove, onMouseDown, isDragging, draggedDistance, ignoreNextClick,
  } = useDraggingScroll();
  return (
    <div className="space-y-4">
      <h4 className="text-base font-bold ml-5">Размер</h4>
      <div
        className="scroll-none flex flex-nowrap overflow-auto gap-2.5"
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
      >
        {sizes.map((size, index) => {
          const isSelected = selected === size.value;
          return (
            <button
              key={`${size.label}-size`}
              onClick={() => {
                if (isDragging || ignoreNextClick) return;
                onChange(size.value);
              }}
              className={cx(
                'flex flex-col text-center text-sm font-semibold text-brand-gray-200 whitespace-nowrap px-2.5 py-0.5',
                index === 0 && 'ml-5',
                index === sizes.length - 1 && 'mr-5',
                isSelected && 'bg-brand-red rounded-full border-brand-red',
              )}
            >
              <span
                className={cx(
                  'text-sm font-semibold mx-auto',
                  isSelected ? 'text-white' : 'text-black',
                )}
              >
                {size.label}
              </span>
              <span
                className={cx(
                  'text-xs font-normal',
                  isSelected ? 'text-white' : 'text-brand-gray-200',
                )}
              >
                {size.label === 'Все' ? 'Все размеры' : `${size.value} см`}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
}
