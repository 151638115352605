import { useCallback, useRef } from 'react';
import useOnScreen from './useOnScreen';
import { FUNCTION_RETURN_NULL } from '../constants';

type Types = {
  cb: () => void,
  disabled: boolean
}

export default function useInfinityScroll({ cb = FUNCTION_RETURN_NULL, disabled = false }: Types) {
  console.log('');
  const ref = useRef(null);
  const callback = useCallback(async () => {
    console.log('load infinity'); await cb();
  }, [cb]);
  useOnScreen({ ref, callback, disabled });
  return ref;
}
