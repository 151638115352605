import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../../../features/constants';
import { getId } from '../../../../features/helpers';

const data = [
  {
    id: getId(),
    image: '/assets/images/friends.png',
    label: 'Зови друзей! +500 руб.',
    href: ROUTES.REFERRAL,
  },
  {
    id: getId(),
    image: '/assets/images/size.png',
    label: <>
      Размерная
      <br />
      сетка
    </>,
    href: ROUTES.SIZES,
  },
  {
    id: getId(),
    image: '/assets/images/info.png',
    label: 'Информация о магазине',
    href: ROUTES.ABOUT,
  },
  {
    id: getId(),
    image: '/assets/images/shopping-bag-svgrepo-com.svg',
    label: <>
      Заказы
      <br />
      <span className="invisible">hide</span>
    </>,
    href: ROUTES.ORDERS,
  },
];

export default function InfoCards() {
  return (
    <div className="w-full grid grid-cols-4 gap-2.5 mt-5">
      {data.map((item, index) => (
        <NavLink
          key={item.id}
          to={item.href}
          className={`h-[100px] flex flex-col items-center justify-between border border-black/10 rounded-18 pb-2.5 px-2.5 ${
            index === 0 ? 'pt-2.5' : index === 1 ? 'pt-1.5' : 'pt-3'
          }`}
        >
          <div
            className={`${
              index === 0 ? 'w-[47px] h-[47px]' : index === 1 ? 'w-[56px] h-[56px]' : 'w-[42px] h-[42px]'
            }`}
          >
            <img src={item.image} alt={item.image} className="w-full h-full" />
          </div>
          <p className="text-[9px] text-center font-medium text-black leading-none">{item.label}</p>
        </NavLink>
      ))}
    </div>
  );
}
