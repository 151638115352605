import { useParams } from 'react-router-dom';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';
import ProductDetails from '../../components/pages/product/ProductDetails';
import ProductDetailsSlider from '../../components/pages/product/ProductSlider';
import { EMPTY_ARRAY } from '../../features/constants';
import useProductModification from '../../api/hooks/useProductModification';
import usePostOrder from '../../api/hooks/usePostOrder';
import useCreateOrder from '../../api/hooks/useCreateOrder';
import { errorToast } from '../../features/helpers';
import useClientInfo from '../../api/hooks/useClientInfo';
import BonusesModal from '../../components/common/Modals/BonusesModal';
import useBonusesInfo from '../../api/hooks/useBonusesInfo';
import LinkPhoneModal from '../../components/common/Modals/LinkPhoneModal';

export type DetailProductStateType = {
  id: string;
  price: string;
  count: number;
  color: string;
  size: string;
};

export default function ProductPage() {
  const [bonusesModalIsOpen, setBonusesModalIsOpen] = useState(false);
  const { id = '' } = useParams();
  const { data: client } = useClientInfo();
  const { data: bonusesInfo } = useBonusesInfo();
  const { mutateAsync: postOrder, isPending: isPostingOrder } = usePostOrder();
  const { mutateAsync: createOrder, isPending: isCreatingOrder } = useCreateOrder();
  const deliveryInfo = client?.delivery_info;
  const bonuses = bonusesInfo?.bonuses || 0;
  const [state, setState] = useState<DetailProductStateType>({
    id: '',
    price: '',
    count: 1,
    color: '',
    size: '',
  });
  const [isOpenLinkPhoneModal, setIsOpenLinkPhoneModal] = useState(false);
  const [confirmUrl, setConfirmUrl] = useState<string | null>(null);
  const {
    data: modificationsList = EMPTY_ARRAY,
    isPending,
    isSuccess,
  } = useProductModification({
    id,
    options: {
      refetchOnWindowFocus: false,
    },
  });

  useEffect(() => {
    window?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  // useEffect(() => {
  //   console.log('My client', client);
  // }, [client]);

  useEffect(() => {
    if (modificationsList.length === 0) return;
    const price = modificationsList[0].price_with_promo || modificationsList[0].price;
    const { id, color, size } = modificationsList[0];
    setState({
      id,
      price,
      count: 1,
      color,
      size,
    });
  }, [modificationsList]);

  const onChangeState = useCallback((object: Partial<DetailProductStateType>) => {
    setState((prev) => ({ ...prev, ...object }));
  }, []);
  const currentModificationImages = useMemo(() => {
    if (modificationsList.length === 0) return undefined;
    return modificationsList.filter(
      (modification) => modification.color.toLocaleLowerCase() === state.color?.toLocaleLowerCase()
        && modification.size === state?.size,
    )?.[0]?.mediafiles;
  }, [state.color, state.size, modificationsList]);

  async function handleBuy({
    useBonuses,
    phone,
    toastText,
  }: {
    phone?: string;
    toastText?: string;
    useBonuses: boolean;
  }) {
    if (deliveryInfo?.phone || client?.phone || phone) {
      try {
        const data = await postOrder({
          items: [
            {
              item: state.id,
              count: state.count,
            },
          ],
          use_bonuses: useBonuses,
          delivery: {
            address: phone || deliveryInfo?.address || '',
            status: 'Требует оформления',
            phone: phone || deliveryInfo?.phone || client?.phone || '',
          },
        });
        const data2 = await createOrder(data.id);
        setConfirmUrl(data2.yookassa.confirmation_url);
        toast.info(toastText ?? 'Ваш заказ оформлен. Для оплаты заказа нажмите кнопку Оплатить', { autoClose: 10000 });
      } catch (e) {
        errorToast({ text: e });
      }
    } else {
      setIsOpenLinkPhoneModal(true);
      errorToast({ text: 'Для оформления заказа необходимо привязать номер телефона' });
    }
  }

  async function registrationAfterLinkingPhone(phone: string) {
    try {
      if (bonuses === 0) {
        await handleBuy({
          useBonuses: false,
          phone,
          toastText: 'Заказ успешно оформлен, теперь вы можете его оплатить, или дождаться звонка менеджера для согласования деталей',
        });
      } else {
        setBonusesModalIsOpen(true);
      }
    } catch (e) {
      errorToast({ text: e });
    }
  }

  function handleRedirectToPayment() {
    if (confirmUrl) {
      window.open(confirmUrl, '_blank');
      setConfirmUrl(null);
    }
  }

  return (
    <div>
      <ProductDetailsSlider images={currentModificationImages} isLoading={isPending} />
      <ProductDetails
        modificationsList={modificationsList}
        state={state}
        setState={onChangeState}
        isLoading={isPending}
        isBuying={isCreatingOrder || isPostingOrder}
        buy={() => (bonuses === 0 ? handleBuy({ useBonuses: false }) : setBonusesModalIsOpen(true))}
        handleRedirectToPayment={handleRedirectToPayment}
        confirmUrl={confirmUrl}
      />
      <LinkPhoneModal
        isOpen={isOpenLinkPhoneModal}
        onClose={() => setIsOpenLinkPhoneModal(false)}
        callback={registrationAfterLinkingPhone}
        info="Для оформления заказа необходимо ввести и подтвердить номер телефона"
      />
      <BonusesModal
        isOpen={bonusesModalIsOpen}
        onClose={() => setBonusesModalIsOpen(false)}
        buy={handleBuy}
      />
    </div>
  );
}
