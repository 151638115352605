import { useEffect } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useQueryClient } from '@tanstack/react-query';
import Modal from '../../Modal';
import FormikInput from '../../Formik/FormikInput';
import { QUERY_KEY_CLIENT_INFO } from '../../../../features/constants';
import useEditDeliveryInfo from '../../../../api/hooks/useEditDeliveryInfo';
import { editDeliveryInfoValidation } from '../../../../features/validations/editDeliveryInfoValidation';
import { clearPhoneNumber, errorToast } from '../../../../features/helpers';
import useClientInfo from '../../../../api/hooks/useClientInfo';
import useCreateDeliveryInfo from '../../../../api/hooks/useCreateDeliveryInfo';

export default function EditDeliveryInfoModal({ isOpen = false, onClose }) {
  return (
    <Modal
      isOpen={isOpen}
      className="pb-14"
      title="Данные доставки"
      onClose={onClose}
    >
      <MainContent onClose={onClose} />
    </Modal>
  );
}

function MainContent({ onClose }) {
  const { data: client } = useClientInfo();
  const deliveryInfo = client?.delivery_info;
  const {
    first_name: firstname = '', second_name: secondname = '', phone = '', last_name: lastname = '', address,
  } = deliveryInfo || {};
  const queryClient = useQueryClient();
  const { mutateAsync: updateInfo } = useEditDeliveryInfo();
  const { mutateAsync: createInfo } = useCreateDeliveryInfo();
  const formik = useFormik({
    initialValues: {
      firstname,
      secondname,
      lastname,
      phone,
      address,
    },
    enableReinitialize: true,
    validationSchema: editDeliveryInfoValidation,
    onSubmit: async ({
      firstname, lastname, secondname, phone, address,
    }) => {
      try {
        const request = deliveryInfo === null ? createInfo : updateInfo;
        await request({
          firstname, lastname, secondname, phone: clearPhoneNumber(phone), address,
        });
        console.log('rrrr 1');
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY_CLIENT_INFO] });
        console.log('rrrr 2');
        onClose();
        console.log('rrrr 3');
      } catch (e) {
        errorToast({ text: e });
        console.log('e', e);
      }
    },
  });
  const { handleSubmit, isSubmitting } = formik;

  useEffect(() => {
    if (isSubmitting && Object.keys(formik.errors).length !== 0) {
      errorToast({ text: formik.errors });
    }
    console.log('linkPhoneValidation err 1', formik.errors);
  }, [formik.errors, isSubmitting]);

  return (
    <AnimatePresence>
      <FormikProvider value={formik}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <FormikInput
            containerClassName="mt-2"
            placeholder="Имя"
            name="firstname"
          />
          <FormikInput
            placeholder="Отчество"
            name="secondname"
          />
          <FormikInput
            placeholder="Фамилия"
            name="lastname"
          />
          <FormikInput
            placeholder="Адрес"
            name="address"
          />
          <FormikInput
            placeholder="Номер телефона"
            mask={['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            name="phone"
          />
          <button
            onClick={() => handleSubmit()}
            className="min-w-[154px] w-1/2 ml-auto block h-12 bg-black rounded-full mt-2 px-5 text-white"
          >
            Сохранить
          </button>
        </motion.div>
      </FormikProvider>
    </AnimatePresence>
  );
}
