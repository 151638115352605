export default function SVGDiscount({ className = '' }: {className?: string}) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.5047 15.3825C9.84857 16.0387 8.78469 16.0387 8.1285 15.3825L1.49213 8.74615C1.17702 8.43106 1 8.00365 1 7.55807V2.8057C1 1.87774 1.75225 1.12549 2.68021 1.12549H7.43258C7.87816 1.12549 8.30557 1.30251 8.62066 1.61761L15.257 8.25399C15.9132 8.91018 15.9132 9.97406 15.257 10.6302L10.5047 15.3825Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.71692 3.84241L4.10505 4.23054"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
