import { useLocation, useNavigate } from 'react-router-dom';
import useTelegramApp from '../../../features/hooks/useTelegramApp';
import { ROUTES } from '../../../features/constants';

function UserInfo() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  //   const router = useRouter();
  //   const pathname = usePathname();
  const telegramApp = useTelegramApp();
  const getFirstName = telegramApp.initDataUnsafe.user?.first_name ?? 'Test';
  const getLastName = telegramApp.initDataUnsafe.user?.last_name ?? 'user';
  //   console.log("telegramApp");
  // @ts-ignore
  console.log(telegramApp.initData);
  if (pathname === ROUTES.USER) {
    return null;
  }
  const redirectToProfile = () => {
    navigate(ROUTES.USER);
  };
  return (
    <div className="flex items-center gap-3.5 px-5 pb-5">
      <div
        className="w-11 h-11 rounded-full border-2 border-black/10 flex justify-center"
        onClick={redirectToProfile}
      >
        <img
          src="/assets/images/avatar.png"
          width={44}
          height={44}
          className="rounded-full"
          alt="user logo"
        />
      </div>
      <span className="font-semibold text-sm" onClick={redirectToProfile}>
        {getFirstName}
        {' '}
        {getLastName}
      </span>
    </div>
  );
}
export default UserInfo;
