import { ProductBrandType } from '../../../../../api/types';
import { NO_IMAGE_LINK } from '../../../../../features/constants';
import {
  globalStoreSelectorFilters,
  useGlobalStore,
} from '../../../../../features/store';

export default function BrandCard({ brand }: { brand: ProductBrandType }) {
  const { setFilters } = useGlobalStore(globalStoreSelectorFilters);

  return (
    <div
      style={{ backgroundImage: `url(${brand.image || NO_IMAGE_LINK})` }}
      className="h-[100px] rounded-18 bg-no-repeat bg-center bg-cover cursor-pointer"
      onClick={() => setFilters({ brand: brand.title, isSearching: true })}
    >
      <div className="bg-[#00000065] rounded-18 h-[100%] p-2 flex items-end">
        <p className="text-white font-bold">{brand.title}</p>
      </div>
    </div>
  );
}
