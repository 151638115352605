import { Colors } from '../app/types';

// manager link
export const MANAGER_TELEGRAM_LINK = process.env.REACT_APP_MANAGER_TELEGRAM_LINK || '';
export const BOT_SUPPORT_TELEGRAM_LINK = process.env.REACT_APP_BOT_SUPPORT_TELEGRAM_LINK || '';
// zustand storage keys
export const STORAGE_KEY = process.env.REACT_APP_STORAGE_KEY;
// routes
export enum ROUTES {
   MAIN = '/',
   SIZES = '/sizes',
   REFERRAL = '/referral',
   HOW_TO_ORDER = '/how-to-order',
   BONUS = '/bonus',
   PRODUCTS = '/products',
   USER = '/user',
   PRODUCT_DETAIL = '/products/:id/:name',
   ABOUT = '/about',
   ORDERS = '/orders'
}
// misc
export function FUNCTION_RETURN_NULL() { return null; }
export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};
export const NO_IMAGE_LINK = '/assets/images/no-image.svg';

// query keys
export const QUERY_KEY_BRANDS = 'QUERY_KEY_BRANDS';
export const QUERY_KEY_PRODUCT_BRANDS = 'QUERY_KEY_PRODUCT_BRANDS';
export const QUERY_KEY_PRODUCTS = 'QUERY_KEY_PRODUCTS';
export const QUERY_KEY_PRODUCTS_INFINITY = 'QUERY_KEY_PRODUCTS_INFINITY';
export const QUERY_KEY_PRODUCTS_MODIFICATIONS = 'QUERY_KEY_PRODUCTS_MODIFICATIONS';
export const QUERY_KEY_REFERRAL_INFO = 'QUERY_KEY_REFERRAL_INFO';
export const QUERY_KEY_CHECK_VERIFIED = 'QUERY_KEY_CHECK_VERIFIED';
export const QUERY_KEY_CLIENT_INFO = 'QUERY_KEY_CLIENT_INFO';
export const QUERY_KEY_BONUSES_INFO = 'QUERY_KEY_BONUSES_INFO';
export const QUERY_KEY_BONUSES = 'QUERY_KEY_BONUSES';
export const QUERY_KEY_ORDERS = 'QUERY_KEY_ORDERS';
export const QUERY_KEY_PROMOTIONS = 'QUERY_KEY_QUERY_KEY_PROMOTIONS';

export const COLORS: Colors = [
  { hex: '#fff', color: 'Белый' },
  { hex: '#0000FF', color: 'Синий' },
  { hex: '#000', color: 'Черный' },
  { hex: '#964B00', color: 'Коричневый' },
  { hex: '#808080', color: 'Серый' },
  { hex: '#1E1E1E', color: 'Глубокий черный' },
  { hex: '#008000', color: 'Зеленый' },
  { hex: '#8B00FF', color: 'Фиолетовый' },
  { hex: '#F5F5DC', color: 'Бежевый' },
  { hex: '#131313', color: 'Карбон Черный' },
  { hex: '#30D5C8', color: 'Бирюзовый' },
  { hex: '#42AAFF', color: 'Голубой' },
  { hex: '#D2B48C', color: 'Загар' },
  { hex: '#ACB78E', color: 'Болотный' },
  { hex: '#FFC0CB', color: 'Розовый' },
  { hex: '#592321', color: 'красно-коричневый' },
  { hex: '#A653EC', color: 'Бело-Фиолетовый' },
  { hex: '#808080:#FFFFFF', color: 'Серый;белый' },
  { hex: '#FFFFFF:#964B00', color: 'Белый;коричневый' },
  { hex: '#FFFFFF:#808080', color: 'Белый;серый' },
  { hex: '#42AAFF:#DBD200', color: 'голубой;Желтый' },
  { hex: '#000000:#42AAFF', color: 'Черный;голубой' },
  { hex: '#FFFFFF:#FFC0CB', color: 'Белый;Розовый' },
  { hex: '#FFFFFF:#FFA500', color: 'Белый;оранжевый' },
  { hex: '#000000:#30D5C8', color: 'Черный;бирюзовый' },
  { hex: '#FFFFFF:#000000', color: 'Белый;черный' },
  { hex: '#FFFFFF:#008000', color: 'Белый;Зеленый' },
  { hex: '#FFFFFF:#0000FF', color: 'Белый;Синий' },
  { hex: '#000000:#0000FF', color: 'Черный;синий' },
  { hex: '#FFFFFF:#FFFF00', color: 'Белый;желтый' },
  { hex: '#FFFFFF:#8B00FF', color: 'Белый;фиолетовый' },
  { hex: '#FFA500:#FFFFFF', color: 'Оранжевый;белый' },
  { hex: '#808080:#0000FF', color: 'Серый;синий' },
  { hex: '#8B00FF:#008000', color: 'Фиолетовый;зеленый' },
  { hex: '#000000:#FF0000', color: 'Черный;красный' },
  { hex: '#808080:#000000', color: 'Серый;черный' },
  { hex: '#FFFFFF:#007FFF', color: 'Белый;лазурный' },
  { hex: '#000000:#FFA500', color: 'Черный;оранжевый' },
  { hex: '#000000:#808080', color: 'Черный;Серый' },
  { hex: '#F5F5DC:#FFC0CB', color: 'Бежевый;Розовый' },
];
