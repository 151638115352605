interface ConfirmSvgProps {
  color: string;
}

function ConfirmSvg({ color }: ConfirmSvgProps) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      className={color?.toLocaleUpperCase() === '#FFF' ? 'fill-black' : 'fill-white'}
    >
      <path d="M4.31077 10.3886L8.66129 14.7001C8.74371 14.7817 8.89 14.7934 9.06798 14.7325C9.24596 14.6717 9.44105 14.5432 9.61034 14.3755C9.77963 14.2077 9.90924 14.0143 9.97067 13.838C10.0321 13.6616 10.0203 13.5166 9.93788 13.4349L5.58736 9.12348C5.50495 9.04181 5.35866 9.03012 5.18068 9.09099C5.00269 9.15187 4.8076 9.28032 4.63832 9.44808C4.46903 9.61585 4.33942 9.80919 4.27799 9.98557C4.21656 10.162 4.22835 10.3069 4.31077 10.3886Z" />
      <path d="M15.3258 6.48394L8.4893 13.2591C8.35979 13.3874 8.30283 13.5772 8.33096 13.7865C8.35908 13.9959 8.46999 14.2078 8.63928 14.3756C8.80857 14.5433 9.02237 14.6533 9.23365 14.6811C9.44494 14.709 9.63639 14.6526 9.7659 14.5242L16.6024 7.74908C16.7319 7.62073 16.7889 7.43099 16.7608 7.22161C16.7327 7.01222 16.6217 6.80034 16.4525 6.63257C16.2832 6.4648 16.0694 6.35489 15.8581 6.32702C15.6468 6.29914 15.4553 6.35559 15.3258 6.48394Z" />
    </svg>
  );
}

export default ConfirmSvg;
