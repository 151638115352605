import { ReferalCountersFront } from '../../../../app/types';
import Skeleton from '../../../ui/Skeleton';
import useReferalInfo from '../../../../api/hooks/useReferalInfo';

interface ReferalCountersProps {
  referalInfo: ReferalCountersFront | undefined;
  isLoading: boolean;
}

export default function ReferalCounters({
  referalInfo,
  isLoading,
}: ReferalCountersProps) {
  const { data: ref } = useReferalInfo();
  console.log('ref', ref);
  const invitedCounter = referalInfo?.invited_clients_count;
  const completedOrdersCounter = referalInfo?.orders_info_by_invited_clients.completed_orders_count;
  const paidOrdersCount = referalInfo?.orders_info_by_invited_clients.paid_orders_count;
  const unPaidOrdersCount = referalInfo?.orders_info_by_invited_clients.unpaid_orders_count;
  const referralBonuses = referalInfo?.referal_bonuses;
  return (
    <>
      <div className="space-y-2 py-5">
        <h5 className="text-base font-bold">Условия</h5>
        <div className="space-y-5">
          <p>
            Баллы начисляются после того как заказ пользователя, перешедшего по реферальной ссылке, был успешно доставлен.
          </p>
        </div>
      </div>
      <div className="space-y-1 pt-5 pb-14">
        {/* <div className="flex items-center justify-between"> */}
        {/*  <p>Переходов по ссылке</p> */}
        {/*  {!isPending ? ( */}
        {/*    <p>{invitedCounter}</p> */}
        {/*  ) : ( */}
        {/*    <Skeleton className="h-5 w-32 rounded-md" /> */}
        {/*  )} */}
        {/* </div> */}
        <div className="flex items-center justify-between">
          <p>Количество рефералов</p>
          {!isLoading ? (
            <p>{invitedCounter}</p>
          ) : (
            <Skeleton className="h-5 w-32 rounded-md" />
          )}
        </div>
        <div className="flex items-center justify-between">
          <p>Сделали заказ</p>
          {!isLoading ? (
            <p>{unPaidOrdersCount}</p>
          ) : (
            <Skeleton className="h-5 w-32 rounded-md" />
          )}
        </div>
        <div className="flex items-center justify-between">
          <p>Оплатили заказ</p>
          {!isLoading ? (
            <p>{paidOrdersCount}</p>
          ) : (
            <Skeleton className="h-5 w-32 rounded-md" />
          )}
        </div>
        <div className="flex items-center justify-between">
          <p>Доставлено заказов</p>
          {!isLoading ? (
            <p>{completedOrdersCounter}</p>
          ) : (
            <Skeleton className="h-5 w-32 rounded-md" />
          )}
        </div>
        <div className="flex items-center justify-between">
          <p>Всего баллов заработано</p>
          {!isLoading ? (
            <p>{referralBonuses}</p>
          ) : (
            <Skeleton className="h-5 w-32 rounded-md" />
          )}
        </div>
      </div>
    </>
  );
}
